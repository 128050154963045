import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
import JSONBIG from 'json-bigint'
import axios from 'axios'; // 引入axios

export default {
    // 复制方法
    copy(str) {
        tools.copyText(str);
        this.$message({
            message: '复制成功',
            type: 'success'
        });
    },
    copyBr(str) {
        tools.copyBr(str, () => {
            this.$message({
                message: '复制成功',
                type: 'success'
            });
        });
    },
    listScroll(e) {
        // let sh = localStorage.getItem('scrollHeight');

        // if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && e.target.scrollHeight != sh) {
        //     console.log(e.target.scrollHeight);
        //     this.req.page += 1;
        //     this.getList();
        //     localStorage.setItem('scrollHeight', e.target.scrollHeight);
        // }
    },
    sortby(field) {
        //记录每个字段的排序方式
        if (this.sortObj[field]) {
            this.sortObj[field] = !this.sortObj[field];
        } else {
            this.sortObj[field] = true;
        }

        this.fieldList.forEach(item => {
            if (item.type === field) {
                // this.$set(item, 'sort', false); //排序方向
                item.active = true;
            } else {
                item.active = false;
            }
        });

        //
        this.tableData = this.tableData.sort((a, b) => {
            if (a[field] > b[field]) {
                return this.sortObj[field] ? 1 : -1;
            } else {
                return this.sortObj[field] ? -1 : 1;
            }
        });
    },

    getFlagColor(color) {
        let rt = '';
        this.flagList.forEach(item => {
            if (item.label === color) {
                rt = item.color;
            }
        });

        return rt;
    },

    getOrderIds() {
        let orders = [];
        this.checkAllItem.forEach(item => {
            orders.push(item);
        });
        return orders;
    },

    // 不同意退货，上传图片
    unAgreeUploadFile(file) {
        if (file.code === 200) {
            console.log(file);
            this.unAgreeRefundGoodsImage = file.font;
        } else {
            this.$message({
                type: 'error',
                message: file.msg
            });
        }
    },
    showGoodsFlag(flag) {
        let orders = this.getOrderIds();
        // 订单
        if (orders.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择订单'
            })
            return;
        }
        if (flag === 1) {
            this.agreeRefundGoodsFlag = true;
        } else if (flag === 2) {
            this.unAgreeRefundGoodsFlag = true;
        }
    },
    // 同意退货
    agreeRefundGoods() {
        let orders = this.getOrderIds();
        // 订单
        if (orders.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择订单'
            })
            return;
        }

        this.refundAddress.orders = JSON.stringify(orders);
        let loader = tools.loading(false, this);

        // 同意退货
        apis.agreeRefundGoods(this.refundAddress).then(res => {
            if (tools.msg(res, this, 1)) {
                this.getList();
                this.agreeRefundGoodsFlag = false;
            }
            tools.loading(loader, this);
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader, this);
        });
    },

    // 
    unAgreeRefundGoods() {
        // 不同意退货提交
        let orders = this.getOrderIds();
        // 订单
        if (orders.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择订单'
            })
            return;
        }
        let req = {
            orders: JSON.stringify(orders),
            refuse_proof: this.unAgreeRefundGoodsImage
        };
        // 
        let loader = tools.loading(false, this);
        apis.unAgreeRefundGoods(req).then(res => {
            if (tools.msg(res, this, 1)) {
                this.getList();
                this.unAgreeRefundGoodsFlag = true;
            }
            tools.loading(loader, this);
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader, this);
        });

    },
    // 确认收货
    confirmReceiveGoods() {
        let orders = this.getOrderIds();
        // 订单
        if (orders.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择订单'
            })
            return;
        }
        this.confirmReceiveGoodsFlag = true;
        // 获取完整订单
        this.confirmReceiveOrders = [];
        apis.getOrderByIds({
            orders: JSON.stringify(orders)
        }).then(res => {
            if (tools.msg(res, this)) {
                this.confirmReceiveOrders = res.data;
            }
            this.confirmReceiveOrders.forEach(ii => {
                ii.titleStr = JSON.parse(ii.title)[0].title;
                ii.list_info.forEach(i => {
                    this.$set(i, 'confirm_num', i.num);
                })
            });

        }).catch(err => {
            tools.err(err, this);
        });

    },
    confirmReceiveGoodsSubmit() {
        // 确认收货
        let req = {};
        this.confirmReceiveOrders.forEach(item => {
            let obj = {};
            item.list_info.forEach(i => {
                obj[i.id] = i.confirm_num;
            })
            req[item.id] = obj;
        });


        let loader = tools.loading(false, this);
        apis.confirmReceiveGoods({
            orders: JSON.stringify(req)
        }).then(res => {
            if (tools.msg(res, this)) {
                this.getList();
                this.confirmReceiveOrders = false;
            }
            tools.loading(loader, this);
        }).catch(err => {
            tools.loading(loader, this);
        });
    },
    agreeRefundMoney() {
        let orders = this.getOrderIds();
        // 订单
        if (orders.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择订单'
            })
            return;
        }

        let loader = tools.loading(false, this);
        apis.agreeRefundMoney({
            orders: JSON.stringify(orders)
        }).then(res => {
            if (tools.msg(res, this, 1)) {

            }
            tools.loading(loader, this);
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader, this);
        });
    },
    unAgreeRefundMoney() {
        this.$alert('拒绝退款请到相关平台操作', {
            beforeClose: (action, instance, done) => {
                document.querySelector('.v-modal').style.display = 'none';
                done();
            }
        });
    },


    // 订单发货
    async sendOrder(flag) {
        let orders = [];
        this.checkAllItem.forEach(item => {
            orders.push({
                order_id: item
            });
        });
        // 订单
        if (orders.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择订单'
            })
            return;
        }
        // 角色二发货
        if (this.loginUser.register_role_id === 2) {
            // 订单发货操作
            await apis.saveOrderSendStatus({
                orders: JSON.stringify(orders)
            }).then(res => {
                if (tools.msg(res, this)) {
                    this.reload();
                }
            }).catch(error => {
                tools.err(error, this);
            });

            return;
        }
    },
    async sendOrderRole3() {
        let orders = [];
        this.checkAllItem.forEach(item => {
            orders.push({
                order_id: item
            });
        });
        // 订单
        if (orders.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择订单'
            })
            return;
        }
        let loader = tools.loading(false, this);
        // 订单发货操作
        await apis.saveOrderSendStatus({
            orders: JSON.stringify(orders),
            warehouse_id: this.send_before_warehouse_id
        }).then(res => {
            if (tools.msg(res, this)) {
                // this.reload();
                this.getList();
                this.select_warehouse_flag = false;
            }
            tools.loading(loader, this);
        }).catch(error => {
            tools.err(error, this);
            tools.loading(loader, this);
        });
    },
    // 发货前选择仓库
    async selectWarehouse() {
        let orders = [];
        this.checkAllItem.forEach(item => {
            orders.push({
                order_id: item
            });
        });
        // 订单
        if (orders.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择订单'
            })

            return;
        }

        let loader = tools.loading(false, this);

        // 获取默认仓库和仓库列表
        await apis.checkWareHouseBind({
            orders: JSON.stringify(orders)
        }).then(res => {
            if (tools.msg(res, this)) {
                this.send_before_warehouse_list = res.data.warehouse;
                this.send_before_warehouse_id = res.data.default_warehouse;
            }
        }).catch(err => {
            tools.err(error, this);
        });

        this.select_warehouse_flag = true;
        tools.loading(loader, this);
    },
    // 发货前选择仓库
    async selectWarehouse() {
        let orders = [];
        this.checkAllItem.forEach(item => {
            orders.push({
                order_id: item
            });
        });
        // 订单
        if (orders.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择订单'
            })

            return;
        }

        let loader = tools.loading(false, this);

        // 获取默认仓库和仓库列表
        await apis.checkWareHouseBind({
            orders: JSON.stringify(orders)
        }).then(res => {
            if (tools.msg(res, this)) {
                this.send_before_warehouse_list = res.data.warehouse;
                this.send_before_warehouse_id = res.data.default_warehouse;
            }
        }).catch(err => {
            tools.err(error, this);
        });

        this.select_warehouse_flag = true;
        tools.loading(loader, this);
    },
    // 发货前选择仓库
    async showCreateSale() {
        let orders = [];
        this.checkAllItem.forEach(item => {
            orders.push({
                order_id: item
            });
        });
        // 订单
        if (orders.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先选择订单'
            })

            return;
        }

        let loader = tools.loading(false, this);

        // 获取默认仓库和仓库列表
        await apis.saleSource().then(res => {
            if (tools.msg(res, this)) {
                this.send_before_warehouse_list = res.data.warehouse;
                this.send_before_warehouse_id = res.data.default_warehouse;
            }
        }).catch(err => {
            tools.err(error, this);
        });

        tools.loading(loader, this);

        this.select_warehouse_flag2 = true;
    },

    //提交订单产生销售单
    async submitOrderToSale() {
        // let orders = [];
        // this.checkAllItem.forEach(item => {
        //     orders.push({
        //         order_id: item
        //     });
        // });

        let loader = tools.loading(false, this);

        // 获取默认仓库和仓库列表
        await apis.orderTranSale({
            orders: JSON.stringify(this.checkAllItem),
            warehouse_id: this.saleWareHouseId
        }).then(res => {
            if (tools.msg(res, this, 1)) {
                this.select_warehouse_flag2 = false;
            }
        }).catch(err => {
            tools.err(error, this);
        });

        tools.loading(loader, this);
    },
    // 显示绑定界面
    async showBindDialog() {
        let loader = tools.loading(false, this);

        this.send_before_select = true;
        let warehoseAll = [];
        let warehoseBind = [];
        let area = [];

        // 生成区域信息
        await apis.getAreaTree().then(res => {
            if (tools.msg(res, this)) {
                area = res.data;
            }
        }).catch(err => {
            tools.err(err, this);
        });
        // 获取所有仓库列表 
        await apis.getWareHouseSource().then(res => {
            if (tools.msg(res, this)) {
                warehoseAll = res.data.warehouse;
            }
        }).catch(err => {
            tools.err(err, this);
        });
        // 获取已绑定数据的仓库列表
        await apis.getWareHouseBinding().then(res => {
            if (tools.msg(res, this)) {
                warehoseBind = res.data;
            }
        }).catch(err => {
            tools.err(err, this);
        });

        // 组装数据
        this.bindData.area = area;
        // 给所有仓库增加一个可选值
        warehoseAll.forEach(item => {
            item.areaSelect = [];
            // 获取已经选中的
            warehoseBind.forEach(i => {
                if (item.id === i.warehouse_id) {
                    item.areaSelect = JSON.parse(i.area);
                }
            });
        });

        this.bindData.warehoseBind = warehoseBind;
        this.bindData.warehoseAll = warehoseAll;

        tools.loading(loader, this);
    },
    //选择区域变化
    checkChange(item, key) {
        //父级菜单
        let res = this.$refs.tree[key].getCheckedNodes();
        item.areaSelect = [];
        res.forEach((i) => {
            if (i.parent_id > 1) {
                item.areaSelect.push(i.id);
            }
        });
    },
    saveBind() {
        let d = this.bindData;
        d.warehoseAll.forEach(item => {
            // 判断有没有选择
            console.log(item);
            if (item.areaSelect.length > 0) {
                //
                // 新增记录
                let req = {
                    warehouse_id: item.id,
                    area: JSON.stringify(item.areaSelect)
                };
                let u = this.hasBind(item);
                if (u.id > 0) {
                    // 更新操作
                    req.id = u.id;
                    apis.updateBind(req).then(res => {
                        if (tools.msg(res, this)) {
                            this.$message({
                                type: 'success',
                                message: '修改成功！'
                            });

                            this.send_before_select = true;
                        }
                    }).catch(err => {
                        tools.err(err, this);
                    });

                } else {
                    apis.addBind(req).then(res => {
                        if (tools.msg(res, this)) {
                            this.$message({
                                type: 'success',
                                message: '增加成功！'
                            });

                            this.send_before_select = true;
                        }
                    }).catch(err => {
                        tools.err(err, this);
                    });

                }
            }
        });


    },
    hasBind(item) {
        let d = this.bindData;
        let flag = {};
        // 判断有没有选择
        d.warehoseBind.forEach(i => {
            if (item.id === i.warehouse_id) {
                flag = i;
            }
        });

        return flag;
    },

    //导出Excel
    exportExcel() {
        this.exportExcelFlag = true;
        apis.exportOrder(this.req).then(res => {
            this.exportStatus = 2;
            let blob = new Blob([res]);
            let url = URL.createObjectURL(blob);
            setTimeout(() => {
                this.exportExcelFlag = false;
                this.exportStatus = 1;
            }, 2500);
            // 下载该blob链接
            tools.downloadFile(url, 'order' + tools.getDateTime() + '.xls')
        }).catch(err => {
            // console.log(err);
            // this.exportStatus = 3;
        });
    },
    //打印退出钩子
    printLodopExit1() {
        this.prepareFlag = false;
    },
    printLodopExit() {
        this.shipmentsFlag = false;
    },
    onSubmit() {

    },
    //显示发货单
    showShipments(flag = false) {
        if (this.checkAllItem.length === 0 && !flag) {
            this.$alert('请最少选择一个订单进行打印');
            return;
        }
        this.autoPrint = flag;
        this.shipmentsFlag = true;
    },
    // 显示备货单
    showPrepare() {
        if (this.checkAllItem.length === 0) {
            this.$alert('请最少选择一个订单进行打印');
            return;
        }
        this.prepareFlag = true;
    },
    showExpress() {
        if (this.checkAllItem.length === 0) {
            this.$alert('请最少选择一个订单进行打印');
            return;
        }
        this.expressFlag = true;
    },
    //合并订单
    mergeOrder() {
        if (this.checkAllItem.length <= 1) {
            this.$alert('请选择两个或以上订单进行合并！');
            return;
        }
        let ids = JSON.stringify(Array.from(this.checkAllItem));
        //合并订单
        apis.mergeOrder({
            orders: ids
        }).then((res) => {
            if (res.code != 200) {
                this.$alert(res.msg);
                return;
            }
            //刷新订单
            this.init();
            this.$message({
                type: 'success',
                message: '合并订单成功！'
            })
        }).catch((error) => {
            this.$message('网络或程序错误，请稍候重试！');
        });
    },
    checkSplit() {
        if (this.checkAllItem.length != 1) {
            this.$alert('一次只能拆分一个订单');
            return;
        }
        this.splitOrderId = Array.from(this.checkAllItem)[0];
        this.splitOrderFlag = true;
    },
    handleSizeChange(val) {
        this.tableData = [];
        this.page = 1;
        this.req.limit = val;
        this.getList();
    },
    handleCurrentChange(val) {
        this.req.page = val;
        this.getList();
    },
    changeFlag(c, detail) {
        detail.seller_flag = c.label;
        detail.seller_flag_value = c.value;
        // apis.saveSellerRemark({
        //     seller_flag: c.value,
        //     seller_memo: detail.seller_memo,
        //     orders: detail.id
        // }).then((res) => {
        //     if (res.code != 200) {
        //         this.$message({
        //             type: 'error',
        //             message: res.msg
        //         })
        //         return;
        //     }
        //     this.$message({
        //         type: 'success',
        //         message: '修改旗帜成功！'
        //     });
        // }).catch((err) => {
        //     this.$message({
        //         type: 'error',
        //         message: '网络或程序错误，请稍候重试！'
        //     });
        // });

    },
    // 获取省市县地址
    getStandardAddress(type, parent_id) {
        apis.getAreaInfo({ parent_id: parent_id }).then((res) => {
            if (tools.msg(res, this)) {
                if (type === 1) {
                    this.province = res.data;
                } else if (type === 2) {
                    this.city = res.data;
                } else if (type === 3) {
                    this.district = res.data;
                } else if (type === 4) {
                    this.town = res.data;
                }
            }
            console.log(this.province);
        }).catch((err) => {
            tools.err(err, this);
        })
    },
    // 修改买家地址
    editAddress(detail) {
        this.getStandardAddress(1, 1);
        this.editAddressFlag = true;
        this.currentOrderDetail = detail;
    },
    saveAddress() {
        let obj = {
            order_id: this.currentOrderDetail.id,
            receiver_zip: this.currentOrderDetail.receiver_zip,
            receiver_state: this.currentOrderDetail.receiver_state,
            receiver_city: this.currentOrderDetail.receiver_city,
            receiver_town: this.currentOrderDetail.receiver_town,
            receiver_address: this.currentOrderDetail.receiver_address,
            receiver_mobile: this.currentOrderDetail.receiver_mobile,
            receiver_name: this.currentOrderDetail.receiver_name,
            receiver_phone: this.currentOrderDetail.receiver_phone,
        };

        apis.saveReceiverAddress(obj).then((res) => {
            tools.msg(res, this) && (this.editAddressFlag = false);
        }).catch((err) => {
            tools.msg(err, this);
        });
    },
    // 获取颜色值
    getValByLabel(label) {
        for (let i = 0; i < this.flagList.length; i++) {
            if (this.flagList[i].label === label) {
                return this.flagList[i].value;
            }
        }
    },
    saveSellerRemark(detail, item) {
        apis.saveSellerRemark({
            seller_flag: detail.seller_flag_value ? detail.seller_flag_value : this.getValByLabel(item.seller_flag),
            seller_memo: item.seller_memo,
            orders: detail.id
        }).then((res) => {
            if (res.code != 200) {
                this.$message({
                    type: 'error',
                    message: res.msg
                })
                return;
            }
            this.$message({
                type: 'success',
                message: '修改卖家备注成功！'
            });
        }).catch((err) => {
            this.$message({
                type: 'error',
                message: '网络或程序错误，请稍候重试！'
            });
        });

    },
    editSellerRemark(detail) {
        this.$set(detail, 'sellerRemarkFocus', !detail.sellerRemarkFocus);
        if (detail.sellerRemarkFocus) {
            document.querySelectorAll('.sellerRemarkTextarea').forEach(element => {
                console.log(typeof element);
                element.focus();
            });
        } else {
            document.querySelectorAll('.sellerRemarkTextarea').forEach(element => {
                console.log(typeof element);
                element.blur();
            });
        }

    },
    reflesh() {
        this.fieldConfigFlag = false;
        this.init();
    },
    resetReq() {
        this.req = tools.copy(this.req_init);
    },

    //执行一些操作时，初始化数据，避免混淆
    initData() {
        this.checkAllItem = [];
        // this.allOrderItem = [];
        this.tableData = [];
    },
    //重新加载页面
    async init() {
        this.initData();

        //重置请求条件
        if (!this.req_init.page) {
            this.req_init = tools.copy(this.req);
        }
        this.resetReq();
        // 获取店铺列表
        // apis.shopList().then((res) => {
        //     tools.msg(res, this) && (this.shopList = res.data);
        // }).catch((error) => {
        //     tools.err(error, this);
        // });
        // 获取平台列表
        apis.getShopPlatform().then((res) => {
            tools.msg(res, this) && (this.platformList = res.data);
        }).catch((error) => {
            tools.err(error, this);
        });
        //先获取字段列表
        await apis.fieldList().then((res) => {
            this.fieldList = res.data;
            this.fieldList.forEach(item => {
                this.$set(item, 'sort', false); //排序方向
                this.$set(item, 'active', false); //排序方向
            });
        }).catch((err) => {
            this.console.log(err);
            this.$message('网络错误，获取数据失败1');
        });

        if (this.tableData.length === 0) {
            await this.getList();
        }
    },
    showConfig() {
        this.$router.push({
            path: '/printSet'
        })
    },
    getOrderType() {
        delete this.req.month;
        delete this.req.history;
        if (this.orderType != 'month3') {
            delete this.req.status;
            delete this.req.refund_status;
        }

        if (this.orderType === 'send') {
            this.req.status = 'WAIT_SELLER_SEND_GOODS';
        } else if (this.orderType === 'sendout') {
            this.req.status = 'SELLER_CONSIGNED_PART|WAIT_BUYER_CONFIRM_GOODS';
        } else if (this.orderType === 'refunding' && this.refundType !== 'goods' && this.refundType !== 'money') {
            this.req.refund_status = 'WAIT_SELLER_AGREE|WAIT_BUYER_RETURN_GOODS|WAIT_SELLER_CONFIRM_GOODS';
        } else if (this.orderType === 'refundall' && this.refundType !== 'goods' && this.refundType !== 'money') {
            this.req.refund_status = 'WAIT_SELLER_AGREE|WAIT_BUYER_RETURN_GOODS|WAIT_SELLER_CONFIRM_GOODS|SELLER_REFUSE_BUYER|CLOSED|SUCCESS';
        } else if (this.orderType === 'month3') {
            this.req.month = 3;
        } else if (this.orderType === 'history') {
            this.req.history = 'all';
        }
    },
    async getList() {
        this.initData();
        this.getOrderType();
        // 获取订单的方法，根据不同的订单不同
        let url = 'api/commerce/orders';
        if (this.refundType === 'goods') {
            url = 'api/commerce/orders_refund_goods_index';
        } else if (this.refundType === 'money') {
            url = 'api/commerce/orders_refund_index';
        }
        // 根据类型获取订单
        apis.orderList(this.req, url).then((res) => {
            let resData = res.data.data;

            this.tableData = resData;
            this.total = res.data.total;
            if (!this.tableData) {
                return;
            }
            let allOrderItem = [];
            this.tableData.forEach((element, key) => {
                allOrderItem.push(element.id + '');
                this.$set(element, '__checked', false);
                this.$set(element, 'showDetail', false);
                this.$set(element, 'detail', { list_info: [] });
                //转换数值显示方式
                for (let field in element) {
                    if (field === 'adjust_fee' || field === 'discount_fee' || field === 'payment') {
                        element[field] = tools.setPrice(element[field]);
                    }
                }
            });
            this.allOrderItem = allOrderItem;
            setTimeout(() => {
                this.theadWidth = document.querySelector('.order_rows').clientWidth;
            }, 600);


        }).catch((err) => {
            console.log(err);
            this.$message('网络或程序错误，请稍候重试！', 'error');
        });
    },
    async search() {
        const loading = this.$loading({
            lock: true,
            text: '搜索中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        this.req.page = 1;
        this.tableData = [];
        await this.getList();

        setTimeout(function() {
            loading.close();
        }, 300);
    },
    remove(id, key) {
        this.$confirm('确定删除此销售单?', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            apis.orderDelete({ id: id }).then((info) => {
                if (info.code == 200) {
                    this.tableData.splice(this.tableData.findIndex(item => item.id === id), 1);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: info.msg
                    });
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    },
    edit(id) {
        this.createShareFlag = true;
        this.currentId = id;
    },
    showImg(i) {
        this.currentUrl = i.product_information.pic_path;
        this.imageFlag = true;
    },
    //获取订单详情
    async getOrderDetail(item, flag) {
        apis.orderDetail({ id: item.id }).then((res) => {
            if (res.code != 200) {
                this.$message(res.msg);
                return;
            }
            let element = res.data;
            //转换数值显示方式
            for (let field in element) {
                if (field === 'adjust_fee' || field === 'discount_fee' || field === 'payment') {
                    element[field] = tools.setPrice(element[field]);
                }
            }
            // 商品件数
            let goods_num = 0;

            element.list_info.forEach((item, key) => {
                goods_num += item.num;
                //
                item.product_information = JSON.parse(item.product_information);

                for (let field in item) {
                    if (field === 'divide_order_fee' ||
                        field === 'adjust_fee' ||
                        field === 'discount_fee' ||
                        field === 'total_fee' ||
                        field === 'price' ||
                        field === 'payment') {
                        item[field] = tools.setPrice(item[field]);
                    }

                    this.$set(item, 'split_num', 0);
                    this.$set(item, 'showImg', false);
                    this.$set(item, 'all_split_num', 0); //已分割的数量累加
                    this.$set(item, 'least_num', item.num + 0);
                    this.$set(item, 'splitCheck', false);
                }
            });

            element.goods_num = goods_num;
            // 地址拼接
            element.receiver_address2 = element.receiver_state + element.receiver_city + (element.receiver_district ? element.receiver_district : '') + element.receiver_town + element.receiver_address;
            // 快递单号遍历
            // let express = new Set();
            // element.waybill_info.forEach((item, key) => {
            //     express.add(item.logistics_companies_info.name);
            // });

            // let expressInfo = {};
            // for (let o of express) {
            //     expressInfo[o] = [];
            // }
            // // 组装 a.韵达 = []的格式
            // for (let k in expressInfo) {
            //     element.waybill_info.forEach((item, key) => {
            //         if (item.logistics_companies_info.name === k) {
            //             expressInfo[k].push(item);
            //         }
            //     });
            // }

            let numTmp = [];
            //组装快递单号： 韵达：1234 申通:1234
            let express = new Set();
            element.waybill_info.forEach((item, key) => {
                express.add(item.logistics_companies_info.name);
            });
            let expressA = {};
            //遍历
            for (let e of express) {
                element.waybill_info.forEach((i, key) => {
                    if (i.logistics_companies_info.name === e) {
                        // console.log(expressA);
                        if (!!expressA[e]) {
                            expressA[e].push(i.waybill_code);
                        } else {
                            expressA[e] = [];
                            expressA[e].push(i.waybill_code);
                        }
                    }
                });
            }

            let num = '';
            for (let i in expressA) {
                num += i + ' : ' + expressA[i].join("\n") + "\n";
            }

            //给列表增加详情信息
            this.$set(element, 'waybill_code', num.trim());
            this.$set(element, 'show_waybill_code', false);
            this.$set(item, 'detail', element);
            if (flag === 1) {
                this.splitChildOrderList = element
            }
        }).catch((error) => {
            console.log(error);
            this.$message('网络或程序错误，请稍候重试！');
        });
    },

    // 添加条件单
    async saveReq(type) {
        let req = tools.copy(this.req);
        req.page = 1;
        let reqId = this.reqId;
        if (type !== 1) {
            this.reqId = 0;
        }

        if (type === 1 && this.reqId === 0) {
            this.$message({
                type: 'error',
                message: '请选择一个条件单进行编辑保存'
            })
            return;
        }

        if (this.reqName.trim() === '') {
            this.$message({
                type: 'error',
                message: '请输入正确的条件单名称'
            })
            return;
        }
        let d = {
            name: this.reqName,
            value: JSON.stringify(req)
        };
        //请求id，用于修改数据
        if (this.reqId !== 0) {
            d.id = this.reqId;
        }

        apis.saveReq(d).then(res => {
            tools.msg(res, this, 1);
            this.getReqList();
            //还原reqId
            this.reqId = reqId;
        }).catch(error => {
            tools.err(error, this);
        });
    },
    // 复现条件单
    async restoreReq(item) {
        let reqData = {};
        await apis.getReqList({ id: item.id }).then(res => {
            if (tools.msg(res, this)) {
                reqData = res.data;
            }
        }).catch(err => {
            tools.err(err, this);
        })

        this.req = reqData.value;
        this.reqId = reqData.id;
        this.reqName = reqData.name;
    },
    //更多条件
    moreCon() {
        this.moreCondition = true;
        //获取条件单
        this.getReqList();
        //获取省市区信息
        this.getStandardAddress(1, 1);
    },
    // 删除条件单
    deleteReq(key, item) {
        if (item.id == this.reqId) {
            this.reqName = '';
            this.reqId = 0;
        }

        this.reqList.splice(key, 1);
        apis.deleteReq({
            id: item.id
        }).then(res => {
            tools.msg(res, this, 1);
            this.getReqList();
            this.reqList.splice(key, 1);
        }).catch(err => {
            tools.msg(err, this);
        });
    },

    // 提交查询
    submitReq() {
        //包含
        if (this.buyer_memo_keyword != '') {
            this.req.buyer_message = this.buyer_memo_keyword;
        }
        if (this.buyer_memo_keyword != '') {
            this.req.seller_memo = this.seller_memo_keyword;
        }
        this.getList();
    },
    // 获取条件单列表
    getReqList() {
        apis.getReqList().then(res => {
            if (tools.msg(res, this)) {
                this.reqList = res.data;
                let reqData = this.reqList[0] ? this.reqList[0] : {};
                // this.req = reqData.value;
                // this.reqId = reqData.id;
                // this.reqName = reqData.name;
            }
        }).catch(err => {
            tools.err(err.this);
        });
    },
    // 取消详情显示
    hideDetail(i) {
        this.checkAllItem = [];
        this.allOrderItem = [];

        this.tableData.forEach(item => {
            if (item.id != i.id) {
                item.showDetail = false;
                item.__checked = false;
            }
            // item.__checked = false;
        });
    },
    showDetail(item) {
        this.hideDetail(item);
        item.showDetail = !item.showDetail;
        //选中操作
        item.__checked = item.showDetail;
        this.currentDetailId = item.id;

        if (item.showDetail) {
            this.checkItem(item);
            //获取订单详情
            this.getOrderDetail(item).then(() => {

            }).catch(() => {
                this.$message('网络或程序错误，请稍候重试！');
            });
        }
    },
    showTitle(item, flag) {
        this.$set(item, 'showTitleFlag', flag);
    },
    checkAll() {
        this.hasCheck = false;
        if (this.isCheckAll) {
            //选择全部
            this.checkAllItem = [...this.allOrderItem];
            this.tableData.forEach(element => {
                this.$set(element, '__checked', true);
            });
        } else {
            //取消选择全部
            this.checkAllItem = [];
            this.tableData.forEach(element => {
                this.$set(element, '__checked', false);
            });
        }
        console.log(this.checkAllItem);
    },
    checkItem(item) {

        if (item.__checked) {
            this.checkAllItem.push(item.id + '');
        } else {
            this.checkAllItem.splice(this.checkAllItem.findIndex(i => i == item.id), 1);
        }
        console.log(this.checkAllItem);

        if (this.checkAllItem.length === this.allOrderItem.length) {
            this.isCheckAll = true;
            this.hasCheck = false;
        } else {
            if (this.checkAllItem.length > 0) {
                this.isCheckAll = false;
                this.hasCheck = true;
            } else {
                this.hasCheck = false;
            }
        }


    }
}