<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" >
        <div class="sale" >
            <!-- 顶部导航 -->
            <div class="box-card" style="background:#fff;" shadow="never">
                <!-- 搜索表单 -->
                <el-form :inline="true" class="demo-form-inline" v-if="true" >
                    <el-form-item label="平台" >
                        <el-select v-model="req.platform" size="small" @change="getList" placeholder="请选择" style="width:150px;">
                            <el-option
                                v-for="item in platformList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="日期">
                        <div class="block">
                              <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.start"
                                size="small"
                                type="datetime"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="开始时间">
                                </el-date-picker>
                            </div>
                            |&nbsp;&nbsp;&nbsp;&nbsp;
                            <div style="display:inline-block;">
                                <span class="demonstration"></span>
                                <el-date-picker
                                v-model="req.end"
                                size="small"
                                format="yyyy-MM-dd HH:mm:ss"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                placeholder="结束时间">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-input
                                placeholder="请输入关键词搜索"
                                size="small"
                                prefix-icon="el-icon-search"
                                v-model="req.keywords"
                        >
                        </el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button size="small"  @click="moreCon">更多条件<i class="el-icon-s-tools" style="font-size:16px;position:relative;top:2px;"></i></el-button>
                    </el-form-item>

                    <el-form-item>
                        <el-button 
                        size="small"
                        type="primary" 
                        @click="search();" icon="el-icon-search">搜索</el-button>
                        <el-button 
                        size="small"
                        @click="resetReq">重置</el-button>
                    </el-form-item>
                </el-form>

                <div class="order_content" 
                id="order_content"
                style="overflow:hidden;position:relative;padding-bottom:80px;width:96%;" >
                    <table class="mytable" style="table-layout:fixed;">
                        <colgroup>
                            <col width="30">
                            <col width="30">
                            <col width="80" v-if="refundType != ''">
                            <col :width="item.width?item.width:130" v-for="(item,key) in cleanFieldList" :key="key">
                        </colgroup>
                        <thead class="thead" id="thead" >
                            <tr class="rows">
                               <td @click="fieldConfigFlag = true;" class="cols" style="width:22px;padding-left:10px;cursor:pointer;">
                                    <i class="el-icon-s-tools" style="font-size:22px;color:orange;"></i>
                                </td>
                                <td class="cols" style="width:20px;padding-left:20px;">
                                    <el-checkbox v-model="isCheckAll" :indeterminate="hasCheck" :checked="isCheckAll" @change="checkAll()"></el-checkbox>
                                </td>
                                <td class="cols" style="width:80px;" v-if="refundType != ''">
                                    退款原因
                                </td>
                                <td v-for="(item,key) in cleanFieldList" @click="sortby(item.type)" :key="key" class="cols order_header" :class="item.active?'active':''" style="cursor:pointer;">
                                    {{item.new_name}} 
                                    <i :class="sortObj[item.type]?'el-icon-top':'el-icon-bottom'" v-if="item.active"></i>
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <div 
                    :style="{height:(__tableHeight__-80)+'px'}"
                    style="overflow-x:hidden;overflow-y:auto;">
                        <table class="mytable" style="table-layout:fixed;" v-for="(item,key) in tableData" :key="key">
                            <colgroup>
                                <col width="30">
                                <col width="30">
                                <col width="80" v-if="refundType != ''">
                                <col :width="item.width?item.width:130" v-for="(item,key) in cleanFieldList" :key="key">
                            </colgroup>
                            <!-- <div style="height:35px;"></div> -->
                            <tbody class="order_rows" >
                                <tr class="rows" :class="{odd:key%2===0}" style="cursor:pointer;" 
                                    :style="{background:(item.showDetail&&currentDetailId===item.id)?'#409EFF':'',color:(item.showDetail&&currentDetailId===item.id)?'#fff':''}"
                                    >
                                    <td class="cols" style="width:20px;padding-left:15px;">
                                        {{key+1}}
                                    </td>
                                    <td class="cols" style="width:20px;padding-left:20px;">
                                        <el-checkbox @change="checkItem(item)" v-model="item.__checked" :checked="item.__checked" ></el-checkbox>
                                    </td>
                                    <!-- 退款原因 -->
                                    <td class="cols" style="width:100px;" v-if="refundType != ''">
                                        {{item.refund_reason?item.refund_reason[0].reason:''}}
                                    </td>

                                    <td class="cols order" v-for="(field,k) in cleanFieldList" :key="k" @click="showDetail(item)">
                                        <span v-if="field.type === 'shop_name'">{{item.shop_info.name}}</span>
                                        <span v-else-if="field.type === 'is_ms'">
                                            <div class="splitDiv" v-if="item.is_ms === 'split'">拆</div>
                                            <div class="splitDiv merge" v-if="item.is_ms === 'merge'">合</div>
                                            <div class="splitDiv merge" v-if="item.is_ms === 'return'">退合</div>
                                        </span>
                                        <span v-else-if="field.type === 'seller_flag'">
                                            <span class="el-icon-s-flag flag active" 
                                            v-if="!!getFlagColor(item.seller_flag)"
                                            :style="{color:getFlagColor(item.seller_flag)}"></span>
                                        </span>
                                        
                                        <span v-else>{{item[field.type]}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td :colspan="cleanFieldList.length" >
                                    <div class="extends" style="transition: height 0.38s;overflow:hidden;" :style="{width:exitendWidth+'px',height:item.showDetail?'300px':'0px'}" >
                                        <div class="info">
                                            <!-- 左侧商品明细 -->
                                            <div class="info_item goods" style="padding:0px;height:250px;">
                                                <table class="mytable" style="width:100%;">
                                                    <thead class="thead">
                                                    <tr class="rows ">
                                                            <td colspan="7" class="cols" 
                                                            style="cursor:pointer;position:relative;">
                                                                <div style="max-width:500px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:#409EFF;">
                                                                    <span v-for="(i,k) in item.detail.list_info" :key="k">
                                                                        {{i.title}}
                                                                    </span>
                                                                    <span v-if="item.detail.list_info.length>1">,</span>
                                                                </div>
                                                                <!-- <i class="el-icon-document-copy icons" 
                                                                @click="copy(item.detail.receiver_mobile)" 
                                                                style="right:30px;position:absolute;right:10px;top:10px;font-size:18px;"></i> -->
                                                            </td>
                                                        </tr>
                                                        <tr class="rows ">
                                                            <td class="cols" >
                                                                
                                                            </td>
                                                            <td class="cols">规格明细</td>
                                                            <td class="cols">数量</td>
                                                            <td class="cols">单价</td>
                                                            <td class="cols">优惠</td>
                                                            <td class="cols">总价</td>
                                                            <td class="cols">操作</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="tbody" v-if="!!item.detail.list_info &&  item.detail.list_info.length>0">
                                                        <tr class="rows " v-for="(i,k) in item.detail.list_info" :key="k">
                                                            <td class="cols">
                                                                <!-- <i class="el-icon-remove rm" title="删除"></i> -->
                                                            </td>
                                                            <td class="cols title" >
                                                                {{i.product_information.sku_properties_name?i.product_information.sku_properties_name:'无'}}
                                                                <i class="el-icon-picture" style="font-size:18px;cursor:pointer;" 
                                                                @click="showImg(i)"></i>
                                                                <!-- <img 
                                                                style="position:absolute;top:0px;left:0px;width:300px;height:300px;"
                                                                :src="i.product_information.pic_path" 
                                                                alt="商品图片" v-if="i.showImg"> -->
                                                                <!-- <div class="title_map" v-show="i.showTitleFlag">
                                                                    {{i.title}}
                                                                </div> -->
                                                            </td>
                                                            <td class="cols">{{i.num}}</td>
                                                            <td class="cols">{{i.price}}</td>
                                                            <td class="cols">{{i.discount_fee}}</td>
                                                            <td class="cols">{{i.divide_order_fee>0?i.divide_order_fee:i.payment}}</td>
                                                            <td class="cols">
                                                                <!-- <i class="el-icon-edit-outline icons" style="font-size:20px;cursor:pointer;"></i> -->
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody class="tbody" v-else>
                                                        <tr class="rows " >
                                                            <td class="cols" colspan="7" >
                                                                <i class="el-icon-loading" style="font-size:22px;"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-if="!!item.detail.list_info &&  item.detail.list_info.length>0">
                                                        <tr class="rows">
                                                            <td colspan="7" class="cols" 
                                                                style="background:#fff;text-align:left;height:30px;">
                                                                <span style="display:inline-block;margin-right:15px;">合计：{{item.detail.goods_num}}件</span>
                                                                <span style="display:inline-block;margin-right:15px;">优惠：{{item.detail.discount_fee}}</span>
                                                                <span style="display:inline-block;margin-right:15px;">运费：0.00</span>
                                                                <span style="display:inline-block;margin-right:15px;">总价：{{item.detail.payment}}</span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!-- 收货人信息 -->
                                            <div class="info_item customer">
                                                <p>     
                                                    昵 称：<span>{{item.detail.buyer_nick}}</span>
                                                </p>
                                                <p>
                                                    收件人：<span>{{item.detail.receiver_name}}</span>
                                                    <!-- <i class="el-icon-document-copy icons" @click="copy(item.detail.receiver_name)" style="right:30px;"></i> -->
                                                </p>
                                                <p>
                                                    电 话：<span>{{item.detail.receiver_mobile}}
                                                        <!-- <i class="el-icon-document-copy icons" @click="copy(item.detail.receiver_mobile)" style="right:30px;"></i> -->
                                                    </span>
                                                </p>
                                                <p style="max-width:230px;">
                                                    地 址：<span>
                                                        <!-- {{item.detail.receiver_state}}
                                                        {{item.detail.receiver_city}}
                                                        {{item.detail.receiver_district}}
                                                        {{item.detail.receiver_town}} -->
                                                        {{item.detail.receiver_address2}}
                                                        <i class="el-icon-document-copy icons" @click="copy(item.detail.receiver_name + enter + item.detail.receiver_mobile + enter + item.detail.receiver_address2)" style="right:-20px;"></i>
                                                        <i class="el-icon-edit-outline icons" style="right:-40px;" @click="editAddress(item.detail)" title="修改收货地址"></i>
                                                    </span>
                                                </p>
                                                <p>
                                                    买家留言：
                                                    <span style="display:block;height:80px;background:#fff;overflow:auto;">
                                                        {{item.buyer_message}}
                                                        <!-- <i class="el-icon-edit-outline icons " ></i>   -->
                                                    </span>
                                                </p>
                                            </div>
                                            <!-- 发货人信息 -->
                                            <div class="info_item sender" v-if="!!item.detail.shop_info">
                                                <p>     
                                                    店 铺：<span>{{item.detail.shop_info.name}}</span>
                                                </p>
                                                <p style="position:relative;" >
                                                    <span class="title_map" v-show="item.detail.show_waybill_code" @mouseout="item.detail.show_waybill_code=false">
                                                        <pre style="text-align:left;">
                                                            {{item.detail.waybill_code}}
                                                        </pre>
                                                    </span>
                                                    <span>快递单号：</span>
                                                    <span  
                                                        @mouseover="item.detail.show_waybill_code=true"
                                                        style="cursor:pointer;color:blue;height:20px;width:150px;display:inline-block;
                                                        white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                                                        {{item.detail.waybill_code}}
                                                    </span>
                                                    <i class="el-icon-document-copy icons" @click="copyBr(item.detail.waybill_code)" style="right:30px;"></i>
                                                    <!-- <i class="el-icon-edit-outline icons" ></i> -->
                                                </p>
                                                <p style="margin:8px 0 8px 0;">
                                                    旗帜：<span v-for="(c,f) in flagList" :key="f">
                                                        <span class="el-icon-s-flag flag" 
                                                        :style="{color:c.color,display:c.value===0?'none':''}" 
                                                        @click="changeFlag(c,item.detail)"
                                                        :class="[c.label==item.detail.seller_flag?'active':'']"></span>
                                                    </span>
                                                </p>
                                                <p>
                                                    卖家备注：
                                                    <span>
                                                        <textarea  class="sellerRemarkTextarea textFocus" v-model="item.seller_memo">
                                                            
                                                        </textarea>
                                                        <el-button 
                                                        type="primary" 
                                                        style="position:absolute;bottom:-40px;left:40%;" size="mini" @click="saveSellerRemark(item.detail,item)">保存</el-button>
                                                        <!-- <i class="el-icon-edit-outline icons " ></i>   -->
                                                    </span>
                                                </p>
                                            </div>
                                            <!-- 订单时间节点 -->
                                            <div class="info_item order_time">
                                                <p>
                                                    订单号：<span>{{item.detail.id}}</span>
                                                    <i class="el-icon-document-copy icons" @click="copy(item.detail.id)" style="right:30px;"></i>
                                                </p>
                                                <p>
                                                    下单时间：<span>{{item.detail.created}}</span>
                                                </p>
                                                <p>
                                                    付款时间：<span>{{item.detail.pay_time}}</span>
                                                </p>
                                                <p>
                                                    发货时间：<span>{{item.detail.consign_time}} </span>
                                                </p>
                                                <p>
                                                    发货单打印时间：
                                                    <span v-if="!!item.detail.send_info && item.detail.send_info.length>0">{{item.detail.send_info[0].create_time}}</span>
                                                    <span v-else>未打印</span>
                                                </p>
                                                <p>
                                                    快递单打印：
                                                    <span v-if="item.is_print_express===1">已打印</span>
                                                    <span v-else>未打印</span>
                                                </p>
                                                <p>
                                                    首次更新时间：<span>{{item.detail.modified}}</span>
                                                </p>
                                                <p>
                                                    最后更新时间：<span>{{item.detail.update_time}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="progress" :style="{width:item.commerce_orders_progress_info.length*265+'px'}">
                                            <div class="step" :title="i.type" :key="k" v-for="(i,k) in item.commerce_orders_progress_info">
                                                <div class="check_radius">
                                                    <i class="el-step__icon-inner is-status el-icon-check"></i>
                                                </div>
                                                <div style="margin-top:3px;position:relative;font-size:16px;">
                                                    {{i.type}}  
                                                    <span class="date">{{i.create_time}}</span>
                                                </div>
                                                <div class="arrow"></div>
                                            </div>
                                        </div>
                                    </div>
                                    </td>
                                </tr>
                            </tbody>

                            <div class="tfooter">

                            </div>
                        </table>
                    </div>
                    <!-- 页码 -->
                    <div class="block" style="position:fixed;bottom:75px;right:75px;background:#fff;">
                        <div style="position:relative;display:inline-block;margin-right:-20px;top:1px;
                        height:28px;line-height:28px;width:130px;">每页： 
                            <el-input size="mini" style="width:58px;" v-model="req.limit" @change="handleSizeChange"></el-input>
                        条</div>
                        <el-pagination
                                style="margin:0 0;display:inline-block;"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="req.page"
                                :page-size="req.limit"
                                layout="jumper,prev, pager, next, total"
                                :total="total"
                                class="flex-right">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
            :modal-append-to-body="false"
            :modal="false"
            title="更多查询信息"
            :visible.sync="moreCondition"
            width="742px"
        >
         <div class="more_search" >
            <div class="search_rows" style="min-height:60px;">
                <div class="title">保存的查询条件</div>
                <div style="display:flex;flex-wrap:wrap;">
                    <div v-for="(item,key) in reqList" :key="key" :style="{border:reqId==item.id?'1px solid orange':'none'}" 
                    @click="restoreReq(item)" class="req_condition">{{item.name}}<i class="el-icon-s-tools"></i>&nbsp;&nbsp;&nbsp;
                        <i class="el-icon-delete" style="color:#f00;font-size:16px;display:inline-block;margin-left:5px;" @click="deleteReq(key,item)" title="删除此条件"></i>
                    </div>
                </div>
            </div>
            <div class="search_rows">
                <div class="title">单据状态</div>
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="快递单" >
                        <el-select v-model="req.is_print_express" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in printSelectList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="备货单" >
                        <el-select v-model="req.is_print_perpare" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in printSelectList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发货单" >
                        <el-select v-model="req.is_print_send" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in printSelectList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>

            <div class="search_rows">
                <div class="title">订单信息</div>
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="旗帜颜色" >
                        <el-select v-model="req.seller_flag" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in flagList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.label"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否合并" >
                        <el-select v-model="req.is_merge" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in boolSelectList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="是否分割" >
                        <el-select v-model="req.is_split" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in boolSelectList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="卖家备注">
                        <el-select v-model="req.seller_memo" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in remarkSelectList2"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                        <span v-show="req.seller_memo===2">包含：<el-input  size="small" v-model="seller_memo_keyword" style="width:120px;"></el-input></span>
                    </el-form-item>
                    <br/>
                    <el-form-item label="买家备注" >
                        <el-select v-model="req.buyer_message" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in remarkSelectList2"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                        <span v-show="req.buyer_message===2">包含：<el-input  size="small" v-model="buyer_memo_keyword" style="width:120px;"></el-input></span>
                    </el-form-item>
                    <br>
                    <el-form-item label="订单状态" >
                        <el-select v-model="req.status" size="small" placeholder="请选择" style="width:230px;">
                            <el-option
                                v-for="item in orderStatusList"
                                :key="item.type"
                                :label="item.label"
                                :value="item.type"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="退款状态" >
                        <el-select v-model="req.refund_status" size="small" placeholder="请选择" style="width:230px;">
                            <el-option
                                v-for="item in orderRefundList"
                                :key="item.type"
                                :label="item.label"
                                :value="item.type"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                </el-form>
            </div>
            <div class="search_rows">
                <div class="title">日期信息</div>
                <el-form :inline="true" class="demo-form-inline" >
                    最近 <el-input type="number" size="small" v-model="req.days" max="10000" style="width:80px;"></el-input> 天
                      <el-popover
                        placement="bottom"
                        title="功能说明"
                        width="200"
                        trigger="hover"
                        content="按自然日自动计算，1天表示当天0点到当前查询时间">
                        <el-button slot="reference" type="text" class="el-icon-info"></el-button>
                    </el-popover>
                </el-form>
            </div>
            <div class="search_rows">
                <div class="title">地区信息</div>
                <el-form :inline="true" class="demo-form-inline" >
                    <el-form-item label="省份" >
                        <el-select v-model="req.receiver_state" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in province"
                                :key="item.id"
                                @click.native="getStandardAddress(2,item.id)"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="地级市" >
                        <el-select v-model="req.receiver_city" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in city"
                                @click.native="getStandardAddress(3,item.id)"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="县区" >
                        <el-select v-model="req.receiver_district" size="small" placeholder="请选择" style="width:120px;">
                            <el-option
                                v-for="item in district"
                                @click.native="getStandardAddress(4,item.id)"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="乡镇" >
                            <el-select v-model="req.receiver_town" size="small" placeholder="请选择" style="width:120px;">
                                <el-option
                                    v-for="item in town"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.name"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>

                   <div style="padding:20px 10px;text-align:right;position:relative;">
                        <div style="position:absolute;left:0px;top:20px;">当前条件单 ： 
                           <el-input style="width:120px;" v-model="reqName" size="small"></el-input>
                        </div>
                       <el-button type="" @click="saveReq(0)" size="small">新增为条件单</el-button>
                       <el-button type="" @click="saveReq(1)" size="small">保存条件单</el-button>
                       <el-button type="" @click="resetReq" size="small">重置所有</el-button>
                       <el-button type="primary" size="small" @click="submitReq">提交查询</el-button>
                   </div>
            </div>
        </div>
        </el-dialog>

        <!-- 配置字段 -->
        <el-dialog
        :modal-append-to-body="false"
        title="配置订单字段"
        :visible.sync="fieldConfigFlag"
        v-if="fieldConfigFlag"
        @close="reflesh"
        width="60%"
        center>
            <fieldConfig  @fleshList="getList" @exit="reflesh"></fieldConfig>
        </el-dialog>
        
        <!--  -->
        <el-dialog
        :modal-append-to-body="false"
        title="导出数据"
        :visible.sync="exportExcelFlag"
        v-if="exportExcelFlag"
        width="60%"
        center>
            <div style="height:50px;width:100%;text-align:center;">
                <i class="el-icon-loading" v-if="exportStatus===1" style="font-size:50px;color:#000;"></i>
                <i class="el-icon-success" v-if="exportStatus===2" style="font-size:50px;color:green;"></i>
            </div>
            <div v-if="exportStatus===1" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                数据正在导出...稍后会自动下载，请留意下载工具
            </div>
            <div v-if="exportStatus===2" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                数据已导出完毕，本窗口即将关闭
            </div>
            <!-- <div v-if="exportStatus===2" style="height:50px;width:100%;text-align:center;font-size:18px;line-height:50px;">
                导出似乎失败了，请关闭本窗口后重试
            </div> -->
            <iframe v-if="exportExcelFlag" :src="exportUrl" frameborder="0" style="opacity:0;height:0px;"></iframe>
        </el-dialog>
        <!-- 合并策略 -->
        <el-dialog
        :modal-append-to-body="false"
        title="配置合并策略"
        :visible.sync="mergeOrderstrategyFlag"
        v-if="mergeOrderstrategyFlag"
        width="40%"
        center>
            <mergeOrderStrategy @exit="mergeOrderstrategyFlag=false"></mergeOrderStrategy>
        </el-dialog>

        <!-- 拆分订单 -->
        <el-dialog
        :modal-append-to-body="false"
        title="拆分订单设置"
        :visible.sync="splitOrderFlag"
        v-if="splitOrderFlag"
        width="80%"
        center>
            <splitOrder @exit="splitOrderFlag=false" :orderId="splitOrderId"></splitOrder>
        </el-dialog>

        <div class="bottom_bar">
            <el-button-group style="margin-left:80px;" v-if="orderType=='send'">
                <el-button class="button" @click="mergeOrder" type="success" size="medium" icon="el-icon-connection" >合并</el-button>
                <el-button type="success" @click="mergeOrderstrategyFlag = true" 
                icon="el-icon-s-tools" size="medium" style="font-size:16px;" title="配置合并策略"></el-button>
            </el-button-group>
                <el-button class="button" @click="checkSplit" type="primary" size="medium" icon="el-icon-menu" v-if="orderType=='send'">拆分</el-button>
            <span v-if="orderType == 'send' || orderType == 'sendout'">
                <el-button class="button" type="" @click="showPrepare" size="medium" icon="el-icon-printer" style="margin-left:80px;">打印备货单</el-button>
                
                <el-button-group  v-if="orderType=='send'">
                    <el-button style="margin-left:50px;" type="" @click="showShipments(false)" size="medium" icon="el-icon-printer" >打印发货单</el-button>
                    <el-button @click="showShipments(true)"  size="medium" icon="el-icon-s-tools" ></el-button>
                </el-button-group>
               
                <el-button class="button" type="primary" @click="showExpress" style="margin-left:50px;" size="medium" icon="el-icon-printer" >打印快递单</el-button>

                <el-button class="button" type="normal" @click="showCreateSale" style="margin-left:30px;" size="medium" icon="el-icon-connection" >生成销售单</el-button>

                <el-button-group  v-if="orderType=='send'">
                    <el-button class="button" @click="sendOrder()" v-if="loginUser.register_role_id===2" type="warning" size="medium" icon="el-icon-connection" >发货</el-button>
                    <el-button class="button" @click="selectWarehouse()" v-if="loginUser.register_role_id===3" type="warning" size="medium" icon="el-icon-connection" >选仓发货</el-button>
                    <el-button type="warning" @click="showBindDialog" v-if="loginUser.register_role_id===3" icon="el-icon-s-tools" size="medium" style="font-size:16px;" title="配置发货仓库"></el-button>
                </el-button-group>
            </span>

            <span v-if="refundType === 'money'">
                <el-button class="button" @click="agreeRefundMoney" size="medium" icon="el-icon-printer" type="success" style="margin-left:80px;">同意退款</el-button>
                <el-button class="button" @click="unAgreeRefundMoney" size="medium" icon="el-icon-printer" type="info" style="margin-left:80px;">拒绝退款</el-button>
            </span>

            <span v-if="refundType === 'goods'">
                <el-button class="button" @click="showGoodsFlag(1)" size="medium" icon="el-icon-printer" type="success" style="margin-left:80px;">同意退货</el-button>
                <el-button class="button" @click="showGoodsFlag(2)" size="medium" icon="el-icon-printer" type="warning" style="margin-left:80px;">不同意退货</el-button>
                <el-button class="button" @click="confirmReceiveGoods" size="medium" icon="el-icon-printer" type="primary" style="margin-left:80px;">确认收货</el-button>
            </span>

            <span v-if="orderType == 'history'">
                <el-button class="button" type="primary" @click="exportExcel" style="margin-left:85px;" size="medium" >导出本页数据</el-button>
            </span>
            <i class="el-icon-s-tools" @click="showConfig" style="position:absolute;top:10px;right:130px;font-size:40px;cursor:pointer;color:orange;"></i>
        </div>
        <!-- 打印备货单 -->
        <el-dialog
        :modal-append-to-body="false"
        title="打印备货单"
        :visible.sync="prepareFlag"
        v-if="prepareFlag"
        width="60%"
        center>
            <printPrepare style="min-height:550px;" 
            :printAction="'order'" 
            :orderAction="'prepare'"
            v-if="prepareFlag"
            :c_status="true"
            :orderIds="checkAllItem" @printLodopExit="printLodopExit1"></printPrepare>
        </el-dialog>
        <!-- 打印发货单 -->
        <el-dialog
        :modal-append-to-body="false"
        :title="autoPrint?'自动打印发货单':''"
        :key="13"
        :visible.sync="shipmentsFlag"
        v-if="shipmentsFlag"
        width="60%"
        center>
            <printOrder style="min-height:550px;" 
            v-if="shipmentsFlag"
            :printAction="'order'" 
            :orderAction="'shipment'"
            :c_status="true"
            :orderIds="checkAllItem" :autoPrint="autoPrint" @printLodopExit="printLodopExit"></printOrder>
        </el-dialog>
        <!-- 打印快递单 -->
        <el-dialog
        :modal-append-to-body="false"
        title="打印快递单"
        :key="131"
        :visible.sync="expressFlag"
        v-if="expressFlag"
        width="1250px"
        center>
            <printExpress style="min-height:550px;" 
            :printAction="'express'" 
            :orderAction="'express'"
            :c_status="true"
            :orderIds="checkAllItem" @printLodopExit="printLodopExit"></printExpress>
        </el-dialog>

        <el-dialog
                :modal-append-to-body="false"
                title="请先选择发货商品的仓库"
                :visible.sync="select_warehouse_flag"
                v-if="select_warehouse_flag"
                width="500px"
                center>
                <div>
                    <el-select v-model="send_before_warehouse_id" placeholder="请选择">
                        <el-option
                        v-for="item in send_before_warehouse_list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <el-button type="primary" @click="sendOrderRole3">确认发货</el-button>
                </div>
        </el-dialog>

        <el-dialog
                :modal-append-to-body="false"
                title="请选择仓库"
                :visible.sync="select_warehouse_flag2"
                v-if="select_warehouse_flag2"
                width="500px"
                center>
                <div>
                    <el-select v-model="saleWareHouseId" placeholder="请选择">
                        <el-option
                        v-for="item in send_before_warehouse_list"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <el-button type="primary" @click="submitOrderToSale">生成销售单</el-button>
                </div>
        </el-dialog>


        <el-dialog
        :modal-append-to-body="false"
        title="请先选择发货商品的仓库"
        :visible.sync="send_before_select"
        v-if="send_before_select"
        width="1300px"
        center>
            <div style="width:100%;height:450px;overflow:auto;display:flex;">
                <el-form label-width="80px" 
                :inline="true" v-for="(item,key) in bindData.warehoseAll" :key="key">
                    <el-form-item label="仓库:" style="width:50%;">
                        <b style="font-weight:900;">&nbsp;{{item.name}}</b>
                    </el-form-item>
                    <el-form-item label="绑定区域:" style="width:50%;">
                            <p>
                                <el-tree
                                @check-change="checkChange(item,key)"
                                ref="tree"
                                :data="bindData.area"
                                :default-checked-keys="item.areaSelect"
                                show-checkbox
                                node-key="id"
                                :props="defaultProps">
                                </el-tree>
                            </p>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button type="primary"  @click="saveBind();">保 存</el-button>
            </div>
        </el-dialog>

        <el-dialog
        :modal-append-to-body="false"
        title="查看大图"
        :visible.sync="imageFlag"
        v-if="imageFlag"
        width="653px"
        center>
            <img :src="currentUrl" alt="" style="width:600px;margin:0 auto;">
        </el-dialog>

        <!-- 同意退货 -->
        <el-dialog
        :modal-append-to-body="false"
        title="请填写退货地址"
        :visible.sync="agreeRefundGoodsFlag"
        v-if="agreeRefundGoodsFlag"
        width="653px"
        center>
            <div>
                <el-form ref="form" :model="refundAddress" label-width="80px">
                    <el-form-item label="退货地址">
                        <el-input v-model="refundAddress.address" ></el-input>
                    </el-form-item>    
                    <el-form-item label="手机号">
                        <el-input v-model="refundAddress.mobile" style="width:200px;"></el-input>
                    </el-form-item>    
                    <el-form-item label="邮编">
                        <el-input v-model="refundAddress.post" style="width:200px;"></el-input>
                    </el-form-item>   
                    <el-form-item label="地址编号">
                        <el-input v-model="refundAddress.seller_address_id" style="width:200px;"></el-input>
                    </el-form-item>   
                    <el-form-item label="备注">
                        <el-input v-model="refundAddress.remark"></el-input>
                    </el-form-item>   
                    <el-form-item label="付邮费">
                        <el-radio v-model="refundAddress.pay" label="1">卖家</el-radio>
                        <el-radio v-model="refundAddress.pay" label="2">买家</el-radio>
                    </el-form-item>   
                    <el-button style="margin-left:230px;" @click="agreeRefundGoods" type="primary">保存地址并同意</el-button>  
                </el-form>
            </div>
        </el-dialog>
        <!-- 不同意退货 -->
        <el-dialog
        :modal-append-to-body="false"
        title="拒绝退货"
        :visible.sync="unAgreeRefundGoodsFlag"
        v-if="unAgreeRefundGoodsFlag"
        width="653px"
        center>
            <div>
                <el-form ref="form"  label-width="80px">
                    <el-form-item label="图片证据">
                        <el-upload
                            :action="uploadUrl"
                            :multiple="false"
                            :headers="authorizationBearer"
                            accept="image/png, image/jpeg"
                            :on-success="unAgreeUploadFile">
                            <el-button size="small" >上传图片</el-button>
                        </el-upload>
                        <img style="width:500px;" :src="baseURL+'/'+unAgreeRefundGoodsImage" v-if="unAgreeRefundGoodsImage != ''" alt="">
                    </el-form-item>    
                    <el-button style="margin-left:230px;" @click="unAgreeRefundGoods" type="primary">提交图片并拒绝</el-button>  
                </el-form>
            </div>
        </el-dialog>
        <!-- 确认收货 -->
        <el-dialog
        :modal-append-to-body="false"
        title="确认收货"
        :visible.sync="confirmReceiveGoodsFlag"
        v-if="confirmReceiveGoodsFlag"
        width="753px"
        center>
            <div>
                 <el-table
                    :data="confirmReceiveOrders"
                    stripe
                    style="width: 100%">
                    <el-table-column label="详情" type="expand" width="80px">
                        <template slot-scope="scope">
                            <table class="mytable">
                                <tbody>
                                    <tr class="rows">
                                        <th class="cols">规格明细</th>
                                        <th class="cols">总数量</th>
                                        <th class="cols">确认收货数量</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr class="rows" v-for="(item,key) in scope.row.list_info" :key="key">
                                        <td class="cols">{{item.title}}</td>
                                        <td class="cols">{{item.num}}</td>
                                        <td class="cols"><el-input-number v-model="item.confirm_num"></el-input-number></td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="订单id" >
                        <template slot-scope="scope">{{ scope.row.id }}</template>
                    </el-table-column>
                    <el-table-column
                    label="名称">
                        <template slot-scope="scope">{{ scope.row.titleStr }}</template>
                    </el-table-column>
                </el-table>

                <el-row style="text-align:center;margin:20px 0 0 0;">
                    <el-button type="primary" @click="confirmReceiveGoodsSubmit">确认收货</el-button>
                </el-row>
            </div>
        </el-dialog>

        <!-- 修改地址 -->
        <el-dialog
        :modal-append-to-body="false"
        title="修改买家地址"
        :key="13"
        :visible.sync="editAddressFlag"
        v-if="editAddressFlag"
        width="60%"
        center>
        <div style="width:600px;margin:0 auto;background:#fff;">
            <div style="text-align:left;">
                    <el-row>
                        <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">选择省市县镇</p>
                        <el-select style="width:130px;" size="small" v-model="currentOrderDetail.receiver_state" :placeholder="currentOrderDetail.receiver_state">
                            <el-option
                            v-for="(item,key) in province"
                            :key="key"
                            @click.native="getStandardAddress(2,item.id)"
                            :label="item.name"
                            :value="item.name">
                            </el-option>
                        </el-select>
                        <el-select size="small" style="width:130px;" v-model="currentOrderDetail.receiver_city"  :placeholder="currentOrderDetail.receiver_city">
                            <el-option
                            v-for="(item,key) in city"
                            :key="key"
                            @click.native="getStandardAddress(3,item.id)"
                            :label="item.name"
                            :value="item.name">
                            </el-option>
                        </el-select>

                        <el-select size="small" style="width:130px;" v-model="currentOrderDetail.receiver_district"  :placeholder="currentOrderDetail.receiver_district">
                            <el-option
                            v-for="(item,key) in district"
                            :key="key"
                            @click.native="getStandardAddress(4,item.id)"
                            :label="item.name"
                            :value="item.name">
                            </el-option>
                        </el-select>
                        <el-select size="small" style="width:130px;" v-model="currentOrderDetail.town"  :placeholder="currentOrderDetail.town">
                            <el-option
                            v-for="(item,key) in district"
                            :key="key"
                            :label="item.name"
                            :value="item.name">
                            </el-option>
                        </el-select>
                    </el-row>
                    <el-row>
                        <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">手机号</p>
                        <el-input size="small" style="width:200px;" v-model="currentOrderDetail.receiver_mobile"></el-input>   
                    </el-row>   
                    <el-row>
                        <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">电话</p>
                        <el-input size="small" style="width:200px;" v-model="currentOrderDetail.receiver_phone"></el-input>   
                    </el-row>                                        
                    <el-row>
                        <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">收货人</p>
                        <el-input size="small" style="width:200px;" v-model="currentOrderDetail.receiver_name"></el-input>   
                    </el-row>
                    <el-row>
                        <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">邮编</p>
                        <el-input size="small" style="width:200px;" v-model="currentOrderDetail.receiver_zip"></el-input>   
                    </el-row>
                    <el-row>
                        <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">详细地址</p>
                        <el-input size="small" v-model="currentOrderDetail.receiver_address"></el-input>   
                    </el-row>
                </div>

                <div slot="footer" class="dialog-footer" style="text-align:center;height:50px;line-height:50px;margin-top:60px;">
                    <el-button type="primary" @click="saveAddress();">保 存</el-button>
                </div>
            </div> 
        </el-dialog>
    </div>
</template>

<script>
import fieldConfig from './fieldConfig';
import printExpress from '../../print/printExpress.vue';
import printPrepare from '../../print/printPrepare.vue';
import printOrder from '../../print/printOrder.vue';
import methods from './method';
import objectData from './objectData';
import mergeOrderStrategy from '../dialogComponents/mergeOrderStrategy.vue';
import splitOrder from '../dialogComponents/splitOrder.vue';
import shipments from '../dialogComponents/shipments.vue';
import prepare from '../dialogComponents/prepare.vue';

export default {
    props:{
        //订单类型
        orderType:{
            type:String,
            default:'send'
        },
        // 退款类型
        refundType:{
            type:String,
            default:'' // goods 退货列表  money 退款列表
        }
    },
    inject: ['selectSide'], 
    data(){
        return objectData;
    },
    components:{
        fieldConfig,mergeOrderStrategy,splitOrder,shipments,prepare,printExpress,printPrepare,printOrder
    },
    methods:methods,
    created(){
        this.init();
    },
    mounted(){
    },
    computed: {
        cleanFieldList: ()=>{
            let newArr = [];
            objectData.fieldList.forEach((item,key)=>{
                if(item.show == 1){
                    newArr.push(item);
                }
            })
            return newArr;
        }
    },
    mounted(){

    }

}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
@import url('../less/order.less');
</style>