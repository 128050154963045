import apis from "../../common/js/api2";
import tools from '../../../extends/tools.js';
import JSONBIG from 'json-bigint';
import Printer from './PrintOrder';
export default {
    async init() {
        let loader = tools.loading(false, this);
        setTimeout(() => {
            tools.loading(loader, this);
        }, 2800);
        //角色二订单打印
        this.selectPrinterFlag2 = true;
        this.ptype = 1;
        if (!this.printAction) {
            this.$alert('请指定打印来源！', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }

        if (this.autoPrint) {
            // 生成区域信息
            await apis.getAreaTree().then(res => {
                if (tools.msg(res, this)) {
                    this.area = res.data;
                }
            }).catch(err => {
                tools.err(err, this);
            });

            // 获取自动返回信息
            await apis.getAutoSendInfo().then(res => {
                if (tools.msg(res, this)) {
                    if (!!res.data && !!res.data.value) {
                        let d = res.data.value;
                        this.areaSelect = d.region;
                        this.currentPrinter = d.print_name;
                        this.currentTemplate = parseInt(d.template_id);
                    }
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }

        // 初始化打印对象
        this.printer = new Printer.Printer(this, this);
        //选择打印机
        this.selectPrinter();
        //
        apis.printList({
            type: this.ptype
        }).then((res) => {
            if (tools.msg(res)) {
                this.templateList_normal = res.data.lists;
            }
        }).catch((error) => {
            tools.err(error);
        });
        //获取对应的数据源字段，备货单和快递单，发货单不一样,
        apis.getDynamicField({
            type: this.ptype
        }).then((res) => {
            tools.msg(res) && (this.fieldList = res.data);
        }).catch((error) => {
            tools.err(error, this);
        });
        // 获取打印配置
        apis.getPrintSet().then(res => {
            if (tools.msg(res, this)) {
                this.printConfig = res.data.basic;
            }
        }).catch(err => {
            tools.err(err, this);
        });
        if (this.autoPrint) {
            return;
        }
        //获取订单信息
        apis.getOrderByIds({
            orders: JSONBIG.stringify(this.orderIds)
        }).then((res) => {
            if (tools.msg(res, this)) {
                this.orderList = res.data;
                console.log(res.data);
                // 订单长度
                this.orderLength = this.orderList.length;
                // 获取默认快递单号
                this.orderList.forEach((item, index) => {
                    for (let o in item) {
                        if (item[o] === null) {
                            item[o] = '';
                        }
                    }

                    if (item.is_print_send === 1) {
                        this.repeatFlag = true;
                    }

                    // 获取简明快递单号
                    // 每个订单需要打印的电子面单数量
                    this.$set(item, 'print_number', 0);
                    this.$set(item, 'showCainiaoDetail', false);
                    this.$set(item, 'showGoodsDetail', false);

                    // 获取商品总数量
                    let all_num = 0;
                    item.list_info.forEach((i) => {
                        all_num += i.num;
                    });
                    this.$set(item.list_info, 'all_num', all_num);

                    if (item.waybill_info.length > 0) {
                        item.waybill_info.forEach((i, d) => {
                            if (d === 0) {
                                this.$set(item, 'waybill_code', i.waybill_code);
                            }
                        });
                    } else {
                        this.$set(item, 'waybill_code', '');
                    }
                });
            }

        }).catch((error) => {
            tools.err(error, this);
        });
    },

    // 开始自动打印
    async startAutoPrint() {
        if (this.startAutoPrintFlag === 1) {
            return;
        }
        //保存配置
        let saveData = {
            print_name: this.currentPrinter,
            template_id: this.currentTemplate,
            region: this.areaSelect
        };

        let aready = false;
        // 保存配置
        await apis.setAutoSendInfo(saveData).then(res => {
            aready = true;
            if (tools.msg(res, this)) {
                this.$message({
                    type: 'success',
                    message: '保存配置成功！'
                })
            }
        }).catch(err => {
            tools.err(err, this);
        });

        if (aready) {
            //开始自动请求打单
            this.send_timer = setInterval(() => {
                if (this.startAutoPrintFlag === 1) {
                    this.requestOrder();
                }
            }, 15000);
        }

        this.startAutoPrintFlag = 1;
    },

    // 请求订单
    requestOrder() {
        let str = localStorage.getItem('CURRENT_ORDER_LIST') ? localStorage.getItem('CURRENT_ORDER_LIST') : '[]';
        let CURRENT_ORDER_LIST = JSON.parse(str);
        console.log('开始打印', CURRENT_ORDER_LIST);
        if (CURRENT_ORDER_LIST.length === 0) {
            return;
        }
        console.log('开始打印2', CURRENT_ORDER_LIST.length);

        this.orderList = tools.copy(CURRENT_ORDER_LIST);

        this.printOrder(1);
    },

    // 
    printOrder(mode) {
        if (this.config.repeat && this.repeatFlag) {
            this.$message({
                type: 'error',
                message: '有订单已经打印过发货单了，请重新选择后再进行打印！'
            });
            return;
        }

        this.printer.printOrder(mode);
    },
    //选择区域变化
    checkChange() {
        //父级菜单
        let res = this.$refs.tree.getCheckedNodes();
        this.areaSelect = [];
        res.forEach((i) => {
            this.areaSelect.push(i.id);
        });
    },
    // 快递模版
    manageExpressTpl() {
        this.$router.push({
            path: '/print'
        });
    },
    exit() {
        this.$emit('printLodopExit');
    },
    back() {
        this.$router.push({
            path: '/print'
        })
    },
    getTplById(id) {
        let tpl = null;
        this.templateList_normal.forEach((item, key) => {
            item.children.forEach((i, k) => {
                if (i.value === id) {
                    tpl = i;
                }
            });
        });
        return tpl;
    },
    selectPrinter() {
        //配置打印机
        let count = this.LODOP.GET_PRINTER_COUNT();
        if (count == 0) {
            this.$alert('无可用打印机', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        let arr = [];
        for (let i = 0; i < count; i++) {
            arr.push(this.LODOP.GET_PRINTER_NAME(i));
        }
        this.printerList = arr;
    },

}