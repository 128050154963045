import NZH from 'nzh';
import orderTrans from './orderTrans';
import apis from '../../common/js/api2';
import tools from '../../../extends/tools.js';

class Printer {
    constructor(host, vue) {
        this.host = host;
        this.trans = new orderTrans.OrderTrans(host);
        this.vue = vue;
        this.pageSize = 5;
        this.emptyLine = true;
        this.tdStyle = '';
    }
    async printOrder(preview) {
        if (!this.host.currentTemplate) {
            this.vue.$alert('请先选择一个模板！', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        //
        if (this.host.currentPrinter === '') {
            this.vue.$alert('请先选择一个打印机！', {
                beforeClose: (action, instance, done) => {
                    document.querySelector('.v-modal').style.display = 'none';
                    done();
                }
            });
            return;
        }
        //获取模板信息
        let TPL = this.trans.getShipmentTemplate(this.host.currentTemplate);
        this.host.paper = JSON.parse(TPL.paper);
        this.host.dragbox = JSON.parse(TPL.dragbox);
        //给分页和空行赋值
        this.host.dragbox.forEach(item => {
            if (item.type === 'shipment') {
                this.pageSize = item.pageSize ? item.pageSize : 5;
                this.borderLine = item.borderLine;
                this.emptyLine = item.emptyLine;
                console.log('shipment: ', item);
                console.log('border line value:', this.borderLine);
                console.log('emptyLine line value:', this.emptyLine);
                let tdStyle = '';
                if (this.borderLine) {
                    tdStyle = '1px solid #000;';
                } else {
                    tdStyle = 'none';
                }
                this.tdStyle = tdStyle;
            }
        });
        // 保存打印发货单状态
        let check_status_flag = true;
        let check_status_word = '';
        // 先整理订单数据，看看能否打印
        let ORDER = this.host.orderList;
        let ORDER_IDS = [];
        //初始化
        ORDER.forEach((rowdata, index) => {
            // 记录订单数据
            ORDER_IDS.push(rowdata.id);
        });
        if (this.host.printAction === 'order' && ORDER_IDS.length > 0) {
            let orders = [];
            ORDER_IDS.forEach((item, key) => {
                orders.push({
                    order_id: item
                });
            });
            await apis.saveOrderSendTplStatus({
                orders: JSON.stringify(orders)
            }).then((res) => {
                if (!tools.msg(res, this.host)) {
                    check_status_flag = false;
                    check_status_word = res.msg;
                    this.startAutoPrintFlag = 2;
                    return;
                }
                //自动打印完成,清空数据
                setTimeout(() => {
                    localStorage.setItem('CURRENT_ORDER_LIST', '[]');
                    console.log('打印完成');
                }, 5000)
            }).catch((error) => {
                tools.err(error, this.host);
                check_status_flag = false;
                let a = 2 / (1 - 1);
                this.startAutoPrintFlag = 2;
            });
        }
        if (!check_status_flag) {
            return false;
        }

        this.printMaker(preview);
    };
    // 打印发货单
    async printMaker(preview) {
        // 打印发货单为例，数据库存储的动态字段是 $$订单id$$  这样的信息，要想替换成动态数据
        // 需要先下载字段列表，字段列表里存储的是 [{name:'订单id',value:'id'}]这样格式的数据
        // 先用$$订单id$$去匹配订单id，获得对应的value值id,再根据类型，套入到对应的列表里获取实际的动态值
        let nzhcn = NZH.cn;
        // 获取订单信息
        let ORDER = this.host.orderList;
        let ORDER_IDS = [];
        //初始化
        ORDER.forEach((rowdata, index) => {
            // 记录订单数据
            ORDER_IDS.push(rowdata.id);
        });

        // 打印
        ORDER.forEach((rowdata, index) => {
            // 记录订单数据
            let printIndex = 'print' + index;
            this.host.LODOP.PRINT_INIT(printIndex);
            this.host.LODOP.SET_PRINT_PAGESIZE(0, this.host.paper.width * 10, this.host.paper.height * 10, printIndex);
            //如果是特殊订单，则打印一个背景水印图
            if (rowdata.is_ms === 'return') {
                let className = 'water';
                this.host.LODOP.ADD_PRINT_TEXTA(className, 1, 0, '100%', '100%', '以此单为准，前单作废,以此单为准'); //增加超文本项
                this.host.LODOP.SET_PRINT_STYLEA(className, 'FontSize', 11.5);
                // this.host.LODOP.SET_PRINT_STYLEA(className, "Angle", -45);
                this.host.LODOP.SET_PRINT_STYLEA(className, "FontColor", '#999999');
            }
            // 循环
            for (var i = 0; i < this.host.dragbox.length; i++) {
                let d = this.host.dragbox[i];
                // 转换发货单数据
                if (d['type'] == 'shipment') {
                    //根据订单的商品数量进行强制分页，分页逻辑：
                    // 分页数量 = 商品数量行数 / 发货单预设每页打印数量
                    let loop = Math.ceil(rowdata.list_info.length / this.pageSize);
                    for (let i = 0; i < loop; i++) {
                        let start = (i) * this.pageSize;
                        let limit = this.pageSize;
                        //分割发货单
                        this.makeShipment(start, limit, d, rowdata, i);
                        // 发送请求给lodop
                        this.host.LODOP.SET_PRINTER_INDEX(this.host.currentPrinter);
                        this.host.LODOP.SET_PRINT_COPIES(this.host.printCopies);
                        if (preview === 1) {
                            this.host.LODOP.PRINT();
                        } else {
                            this.host.LODOP.PREVIEW();
                        }
                    }
                }
            }

            if (this.host.config.close && preview == 1) {
                this.host.exit();
            }
        });
    }

    makeShipment(start, limit, d, rowdata, pageIndex) {
        for (var i = 0; i < this.host.dragbox.length; i++) {
            let d = this.host.dragbox[i];
            let top = d.boxObject.top;
            let left = d.boxObject.left;
            let width = d.boxObject.width;
            let height = d.boxObject.height;
            let deg = d.boxObject.deg;
            let textFontSize = d.textObject.fontSizeNum;
            delete d.boxReverse.transform;
            delete d.boxReverse.writeingMode;
            //需要转换的动态数据
            // console.log(d);
            // console.log(rowdata);
            for (let k in rowdata) {
                //k是订单的字段信息，比如数量 num
                //处理复杂字段
                let flag2 = this.trans.getComplexDataFlag(d);
                if ((d.type === 'text' && d.source === k) || flag2) {
                    // 填充样式
                    let allText = d.text + '';
                    // 替换动态数据
                    if (!!d.source && d.source === k) {
                        if (d.text) {
                            // console.log(d.text);
                            allText = allText + ':' + rowdata[k];
                        } else {
                            allText = rowdata[k];
                        }
                    }
                    if (flag2) {
                        allText = this.trans.transOrderdata(d, rowdata);
                    }
                    // 转化
                    let textObject = d.textObject;
                    if (deg == 90) {
                        let tmp = width;
                        width = height;
                        height = tmp + 20;
                        top -= (height - width) / 2;
                        left += (height - width) / 2;
                    }
                    // 处理大写转换
                    if (textObject.textTransform == "uppercase") {
                        allText = nzhcn.toMoney(allText);
                        allText = allText.replace('人民币', '');
                    }
                    let className = 'text' + i;
                    this.host.LODOP.ADD_PRINT_TEXTA(className, top, left, width, height, allText); //增加超文本项
                    // 旋转变换
                    if (deg == 90) {
                        this.host.LODOP.SET_PRINT_STYLEA(className, "LineSpacing", -6);
                    }
                    // 处理居中居左
                    if (d.boxReverse.textAlign == 'center') {
                        this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 2);
                    } else if (d.boxReverse.textAlign == 'right') {
                        this.host.LODOP.SET_PRINT_STYLEA(className, "Alignment", 3);
                    }
                    this.host.LODOP.SET_PRINT_STYLEA(className, 'FontName', textObject.fontFamily);
                    this.host.LODOP.SET_PRINT_STYLEA(className, 'FontSize', textFontSize);
                    if (textObject.fontWeigiht == 'bold') {
                        this.host.LODOP.SET_PRINT_STYLEA(className, 'Bold', 1);
                    }
                    if (textObject.fontStyle == 'italic') {
                        this.host.LODOP.SET_PRINT_STYLEA(className, 'Italic', 1);
                    }
                    if (textObject.textDecoration == 'underline') {
                        this.host.LODOP.SET_PRINT_STYLEA(className, 'Underline', 1);
                    }
                }

                // 转换条形码数据
                if (d['type'] == 'barCode' && d.source === k) {
                    if (deg == 90) {
                        let tmp = width;
                        width = height;
                        height = tmp;
                        top -= (height - width) / 2;
                        left += (height - width) / 2;
                    }

                    this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, d.format, rowdata[k]);
                    if (deg == 90) {
                        this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
                    }
                }
                // 转换二维码
                if (d['type'] == 'qr' && d.source == k) {
                    if (deg == 90) {
                        let tmp = width;
                        width = height;
                        height = tmp;
                        top -= (height - width) / 2;
                        left += (height - width) / 2;
                    }
                    // console.log(rowdata[k]);
                    this.host.LODOP.ADD_PRINT_BARCODE(top, left, width, height, 'QRCode', rowdata[k]);
                    this.host.LODOP.SET_PRINT_STYLEA(0, "QRCodeVersion", 20);
                    if (deg == 90) {
                        this.host.LODOP.SET_PRINT_STYLEA(0, "Angle", 90);
                    }
                }
            }

            //转换页签
            if (d.type === 'text') {
                if (d.source === 'currentPage') {
                    let allText = '第 ' + (pageIndex + 1) + ' 页';
                    this.host.LODOP.ADD_PRINT_TEXT(top, left, width, height, allText); //增加超文本项
                }

                if (d.source === 'currentDatetime') {
                    this.host.LODOP.ADD_PRINT_TEXT(top, left, width, height, tools.now()); //增加单据打印时间
                }

            }

            //转换静态数据
            this.trans.getStaticHTML(d);
            //处理发货单
            if (d.type === 'shipment') {
                //创建表格
                let tableDiv = document.createElement('div');
                tableDiv.className = 'shipment';
                for (let s in d.boxReverse) {
                    tableDiv.style[s] = d.boxReverse[s];
                }
                let table = document.createElement('table');

                for (let s in d.tableStyle) {
                    table.style[s] = d.tableStyle[s];
                }
                //处理发货单框线问题
                //表格的框线
                table.style.border = this.tdStyle;
                tableDiv.appendChild(table);
                //处理第一行表头
                {
                    let i = 0;
                    let tr = document.createElement('tr');
                    for (let j = 0; j < d.rows[i].length; j++) {
                        let td = document.createElement('td');
                        td.setAttribute('cellspacing', 0);
                        let unit = d.rows[i][j];
                        for (let s in unit.style) {
                            td.style[s] = unit.style[s];
                        }
                        td.innerHTML = unit.valueShow;
                        //处理发货单框线问题
                        td.style.border = this.tdStyle;

                        tr.appendChild(td);
                    }
                    table.appendChild(tr);
                }
                // 统计总数问题
                let totalObj = {
                    payment: 0, //实际支付总价
                    num: 0, //商品数量总数
                    price: 0 //商品价格总数
                };
                // 处理中间商品列表数据
                // 获得商品数据
                let GOODS = rowdata.list_info;

                {
                    //空行模板
                    let itemTpl = tools.copy(GOODS[0]);
                    let itemLength = 1;
                    for (let i in itemTpl) {
                        itemLength++;
                        itemTpl[i] = '';
                    }

                    for (let i = start; i < start + limit; i++) {
                        let item = GOODS[i];
                        let key = i;

                        let tr = document.createElement('tr');

                        if (item === undefined && !this.emptyLine) {
                            console.log('empty line value:', this.emptyLine);
                            let td = document.createElement('td');
                            td.style.border = 'none';
                            // td.style.borderLeft = "1px solid #000";
                            // td.style.borderRight = "1px solid #000";
                            td.colSpan = itemLength;
                            tr.appendChild(td);
                        } else {
                            if (item === undefined) {
                                item = tools.copy(itemTpl);
                            }
                            for (let j = 0; j < d.rows[0].length; j++) {
                                let fieldValue = this.trans.getOrderFieldValue(d.rows[0][j].valueShow);
                                let td = document.createElement('td');
                                td.setAttribute('cellspacing', 0);
                                // 因为本行是动态行，没有自己的样式，直接复制第一行的样式即可
                                let unit = d.rows[0][j];
                                for (let s in unit.style) {
                                    td.style[s] = unit.style[s];
                                }
                                //转换实际显示值
                                if (j === 0) {
                                    if (item.create_id) {
                                        td.innerHTML = key + 1;
                                    } else {
                                        td.innerHTML = '';
                                    }
                                } else {
                                    let vv = this.trans.formatGoodsVal(fieldValue, item[fieldValue]);
                                    // 累积数量和价格
                                    totalObj[fieldValue] !== undefined && (totalObj[fieldValue] += parseFloat(vv));
                                    //
                                    td.innerHTML = this.trans.formatGoodsVal(fieldValue, item[fieldValue]);
                                }
                                //处理发货单框线问题
                                //表格的框线
                                td.style.border = this.tdStyle;
                                tr.appendChild(td);
                            }
                        }
                        table.appendChild(tr);
                    }
                    // GOODS.forEach((item, key) => {

                    // });
                }
                // 统计总数问题
                totalObj = {
                    payment: 0, //实际支付总价
                    num: 0, //商品数量总数
                    price: 0 //商品价格总数
                };

                {
                    GOODS.forEach((item, key) => {
                        for (let j = 0; j < d.rows[0].length; j++) {
                            let fieldValue = this.trans.getOrderFieldValue(d.rows[0][j].valueShow);
                            // 因为本行是动态行，没有自己的样式，直接复制第一行的样式即可
                            let unit = d.rows[0][j];
                            //转换实际显示值
                            if (j === 0) {} else {
                                let vv = this.trans.formatGoodsVal(fieldValue, item[fieldValue]);
                                // 累积数量和价格
                                totalObj[fieldValue] !== undefined && (totalObj[fieldValue] += parseFloat(vv));
                            }
                        }
                    });
                }
                // 处理尾部统计
                {
                    let i = 0;
                    let tr = document.createElement('tr');
                    for (let j = 0; j < d.rows[i].length; j++) {
                        let td = document.createElement('td');
                        td.setAttribute('cellspacing', 0);
                        let unit = d.rows[i][j];
                        for (let s in unit.style) {
                            td.style[s] = unit.style[s];
                        }
                        let fieldValue = this.trans.getOrderFieldValue(d.rows[0][j].valueShow);
                        let txt = '';
                        if (unit.valueShow === '序号') {
                            txt = '汇总:';
                        } else {
                            txt = totalObj[fieldValue] !== undefined ? totalObj[fieldValue] : '';
                        }

                        td.innerHTML = txt;
                        //处理发货单框线问题
                        let tdStyle = '';
                        if (this.borderLine) {
                            tdStyle = '1px solid #000;';
                        } else {
                            tdStyle = 'none';
                        }
                        //表格的框线
                        td.style.border = tdStyle;

                        tr.appendChild(td);
                    }
                    table.appendChild(tr);
                }

                this.host.LODOP.ADD_PRINT_HTM(top, left, width, height, tableDiv.outerHTML); //增加超文本项
            }
        }
    }

}

export default {
    Printer
}