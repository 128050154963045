export default {
    printer: null,
    initData: null,
    selectFileData: [],
    dragbox: [],
    paper: {},
    area: [],
    areaSelect: [],
    startAutoPrintFlag: -1,
    // 树形菜单默认匹配属性
    defaultProps: {
        children: 'city',
        label: 'name'
    },

    tplId: 0,
    // lodop打印机
    printerList: [],
    currentPrinter: '',
    // 菜鸟打印机
    printerListCainiao: [],
    currentPrinterCainiao: '',
    //菜鸟账户列表
    cainiaoAccountList: [],
    // 根据菜鸟账户生成的站点列表
    cainiaoSiteList: [],
    // 选择的账户
    selectCainiaoAccount: '',
    // 选择的站点
    selectCainiaoSite: '',
    // 菜鸟发件人姓名
    cainiaoSender: '',
    cainiaoSendPhone: '',
    // 设为默认
    cainiaoSetDefault: true,
    cainiaoAccountFlag: false,
    // 
    currentTable: {},
    printSuccess: false,
    selectPrinterFlag1: false,
    selectPrinterFlag2: false,
    selectPrinterFlag3: false,
    loading1: null,
    loading2: null,
    text1: '',
    text2: '',
    textStyle: {},
    boxStyle: {},
    installLodop: false,
    printCopies: 1,
    //发货单,备货单选中的模版
    currentTemplate: '',
    //快递公司列表
    expressList: [],
    // 初始化加载的模版列表
    templateList_normal: [],
    templateList_express: [],
    templateList_cainiao: [],
    repeatFlag: false,
    config: {
        isA: false,
        tuikuan: true,
        close: false,
        repeat: true
    },
    printConfig: {

    },
    send_timer: null,
    //模式，普通，菜鸟
    expressMode: '菜鸟',
    // 订单列表，适用发货单，快递单
    orderList: [],
    // 备货单，有聚合字段
    prepareOrder: [],
    // 系统变量字段，和设计模版时的保持一致,打印不同单据时字段不一样
    fieldList: [],
    //模版id，当模式为菜鸟和普通时有不同含义
    templateId: '',
    cainiaoTemplateDetail: {},
    // 模版类型
    ptype: 0,
    // 订单总长度
    orderLength: 0,
    // 已打印快递单总长度
    sendOrderLength: 0
}