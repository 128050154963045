<template>
  <div>
       <!-- 创建 -->
        <div style="width:100%;background:#fff;">
            <div style="display:flex;">
                <div style="width:80%;height:500px;overflow-y:auto;background:#fff;border:1px solid #eee;">
                    <table class="mytable">
                        <thead class="thead">
                            <tr class="rows">
                                <td class="cols">行号</td>
                                <td class="cols">列名</td>
                                <td class="cols">显示名称</td>
                                <td class="cols">显示状态</td>
                            </tr>
                        </thead>

                        <tbody class="tbody" >
                            <tr class="rows" @mousedown="focusRows(item,key)" 
                            v-for="(item,key) in fieldList" :key="key"
                            v-dragging="{ item: item, list: fieldList, group:'fields'}" 
                            :style="{background:item.clickActive?'#fefae3':''}">
                                <td class="cols" >{{key+1}}</td>
                                <td class="cols">{{item.name}}</td>
                                <td class="cols" title="双击编辑"><input type="text" readonly="readonly" :class="[item.editActive?'editActive':'']" @blur="endEdit" @dblclick="editBox"  v-model="item.new_name" class="inputer" /></td>
                                <td class="cols">
                                    <i class="el-icon-check" v-show="item.show == 1" @click="showItem(item,2)" style="color:green;cursor:pointer;font-size:16px;"></i>
                                    <i class="el-icon-close" v-show="item.show == 2" @click="showItem(item,1)" style="color:red;cursor:pointer;font-size:16px;"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 按钮 -->
                <div style="width:20%;height:500px;background:#fff;text-align:center;border:1px solid #eee;">
                    <el-row class="button_rows">
                        <el-button size="small" style="width:100px;" @click="move(1)" :disabled="currentKey===0 || currentKey<0">上移</el-button>
                    </el-row>
                    <el-row class="button_rows">
                        <el-button size="small" style="width:100px;" @click="move(-1)" :disabled="currentKey===fieldList.length-1 || currentKey<0">下移</el-button>
                    </el-row>

                    <el-row class="button_rows">
                        <el-button size="small" style="width:100px;" @click="move(0)" :disabled="currentKey===0 || currentKey<0">置顶</el-button>
                    </el-row>
                    <el-row class="button_rows">
                        <el-button size="small" style="width:100px;" @click="move(-99)" :disabled="currentKey===fieldList.length-1 || currentKey<0">置底</el-button>
                    </el-row>
                    <el-row class="button_rows"></el-row>
                    <el-row class="button_rows"><el-button size="small" @click="setDefault" type="success" style="width:100px;">还原默认设置</el-button></el-row>
                    <el-row class="button_rows"><el-button size="small" @click="save" type="primary" style="width:100px;">保存当前</el-button></el-row>
                </div>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button type="primary" @click="exit();">关 闭</el-button>
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            fieldList:[],
            currentKey:-1
        }
    },
    methods:{
        setDefault(){
            this.$confirm('确定要还原默认设置吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                //初始化字段数据
                apis.fieldList({
                    reduction:1
                }).then((res)=>{
                    this.fieldList = res.data;
                    this.save();
                    this.$message({
                        type:'success',
                        message:'还原默认设置成功！'
                    });
                }).catch((err)=>{
                    // this.fieldList = err;
                    this.$message('网络错误，获取数据失败1');
                }); 
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });          
            });
        },
        //编辑
        editBox(e){
            e.target.removeAttribute('readonly');
            this.fieldList.forEach((item)=>{
                this.$set(item,'editActive',false);
            })
            this.$set(this.fieldList[this.currentKey],'editActive',true);
        },
        endEdit(e){
            e.target.setAttribute('readonly','readonly');
            this.fieldList.forEach((item)=>{
                this.$set(item,'editActive',false);
            })
        },
        focusRows(item,key){
            this.currentKey = key;
            this.fieldList.forEach((item)=>{
                this.$set(item,'clickActive',false);
            })
            this.$set(item,'clickActive',!item.clickActive);
        },
        showItem(item,status){
            item.show = status;
            console.log(item.show);
        },
        //移动
        move(type){
            let tmpObj = tools.copy(this.fieldList[this.currentKey]);
            let all_length = this.fieldList.length-1;
            // type 1 向上一格
            // type -1 向下一格
            // type 0 置顶
            // type 99 置底
            if(Math.abs(type) == 1){
                this.$set(this.fieldList,this.currentKey,this.fieldList[this.currentKey-type]);
                this.$set(this.fieldList,this.currentKey-type,tmpObj);
                this.currentKey += -type;
            }else if(type === 0){
                this.$set(this.fieldList,this.currentKey,this.fieldList[0]);
                this.$set(this.fieldList,0,tmpObj);
                this.currentKey = 0;
            }else{
                this.$set(this.fieldList,this.currentKey,this.fieldList[all_length]);
                this.$set(this.fieldList,all_length,tmpObj);
                this.currentKey = all_length;
            }
            if(this.currentKey <= 0){
                this.currentKey = 0;
            }
        },
        //保存数据
        save(){
            let saveData = []
            saveData = this.fieldList.map((item,key)=>{
                item.sort = key;
                return item;
            });
            
            //保存字段
            apis.saveField({value:JSON.stringify(saveData)}).then((res)=>{
                if(res.code != 200){
                    this.$message({
                        type:'error',
                        message:res.msg
                    });
                    return;
                }
                this.$message({
                    type:'success',
                    message:'保存成功！'
                })
            }).catch((err)=>{
                console.log(err);
                this.$message('网络错误，获取数据失败');
            });

        },  
        exit(){
            this.$emit('exit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    created(){
        //初始化字段数据
        apis.fieldList({}).then((res)=>{
            this.fieldList = res.data;
            // this.fieldList = this.fieldList.reverse();
            // console.log(this.fieldList);
        }).catch((err)=>{
            this.$message('网络错误，获取数据失败1');
        });
    },
    mounted(){
        this.$dragging.$on('dragend', (v) => {
            this.fieldList.forEach((item,key)=>{
                if(item.clickActive){
                    this.currentKey = key;
                }
            })
        })
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.button_rows{
    height:45px;line-height: 45px;
}
.mytable{
    user-select: none;
}
.rows .cols{
    min-height: 20px!important;
    line-height: 20px!important;
    .inputer{
        background:none;
    }
}
input{
    user-select: none;
}
.editActive{
    background: #fff !important;border:1px solid #ccc;height:20px;
}
</style>