import tools from "../../../extends/tools";

export default {
    // 条件单
    reqList: [],
    loginUser: JSON.parse(localStorage.getItem('userinfo')),
    authorizationBearer: tools.getToken(),
    uploadUrl: tools.baseURL() + 'api/admin/file',
    send_before_select: false,
    exportExcelFlag: false,
    unAgreeRefundGoodsImage: '',
    baseURL: tools.baseURL(),
    autoPrint: false,

    // 退货相关
    agreeRefundGoodsFlag: false,
    unAgreeRefundGoodsFlag: false,
    confirmReceiveGoodsFlag: false,
    confirmReceiveOrders: [],
    refundAddress: {
        address: '',
        mobile: '',
        seller_address_id: '',
        remark: '',
        pay: '1',
        orders: ''
    },


    // 绑定仓库和区域数据
    bindData: {
        // 所有的仓库和对应的区域
        warehoseAll: [],
        warehoseBind: [],
        // 所有的地区
        area: []
    },
    sendWarehouseId: 0,
    saleWareHouseId: '',
    select_warehouse_flag2: false,
    // 树形菜单默认匹配属性
    defaultProps: {
        children: 'city',
        label: 'name'
    },
    select_warehouse_flag: false,
    // 发货前检查的数据
    send_before_warehouse_list: [],
    send_before_warehouse_id: '',


    exportUrl: '',
    exportStatus: 1,
    reqId: 0,
    reqName: '',
    // 排序字段
    sortByField: 'id',
    enter: "  ",
    theadWidth: '',
    currentDetailId: '',
    sortObj: {

    },
    selectSide: localStorage.getItem('selectSide') ? localStorage.getItem('selectSide') : 'left',
    // 修改地址标志
    editAddressFlag: false,
    currentUrl: '',
    imageFlag: false,
    srcList: [],
    // 当前地址
    currentOrderDetail: null,
    // shopList: [],
    platformList: [],
    // 收货省市区镇
    province: [],
    city: [],
    district: [],
    town: [],
    // 发货单
    shipmentsFlag: false,
    shipmentsOrderIds: [],
    // 备货单
    prepareFlag: false,
    prepareOrderIds: [],
    // 快递单
    expressFlag: false,
    expressIds: [],

    //分割选中的订单id
    splitOrderId: 0,
    //上传图片列表地址
    uploadImages: [

    ],
    //选中项目列表
    checkAllItem: [],
    //默认全部项目
    allOrderItem: [],
    //是否全选
    isCheckAll: false,
    //是否有选中
    hasCheck: false,
    //字段配置
    fieldConfigFlag: false,
    //伸缩框宽度
    exitendWidth: document.body.clientWidth - 130,
    // 分割订单标志
    splitOrderFlag: false,
    //合并策略标志
    mergeOrderstrategyFlag: false,
    showTitleFlag: false,
    createShareFlag: false,
    shareListFlag: false,
    currentId: 0,
    tableData: [],
    total: 0,
    currentPage: 1,
    shareId: 0,
    statusList: [],
    orderDetail: {

    },
    //字段列表
    fieldList: [{
        name: 'id',
        label: '订单号'
    }, {
        name: 'status',
        label: '状态'
    }, {
        name: 'seller_flag',
        label: '旗帜'
    }, {
        name: 'status',
        label: '订单状态'
    }, {
        name: 'buyer_message',
        label: '买家备注'
    }],
    // 旗帜
    flagList: [{
            // 红、黄、绿、蓝、紫 
            color: 'red',
            label: '红',
            value: 1
        },
        {
            color: 'orange',
            label: '黄',
            value: 2
        }, {
            color: 'green',
            label: '绿',
            value: 3
        }, {
            color: 'blue',
            label: '蓝',
            value: 4
        }, {
            color: 'purple',
            label: '紫',
            value: 5
        }, {
            color: 'none',
            label: '无',
            value: 0
        }
    ],

    seller_memo_keyword: '',
    buyer_memo_keyword: '',
    req_init: {},
    req: {
        keywords: '',
        days: '',
        platform: '', //平台
        is_print_send: '',
        is_print_express: '',
        is_print_perpare: '',
        seller_flag: '',
        buyer_message: '',
        seller_memo: '',
        is_merge: '',
        is_split: '',
        receiver_state: '',
        receiver_city: '',
        receiver_district: '',
        receiver_town: '',
        limit: 30,
        page: 1,
        start: '',
        month: '',
        history: '',
        status: '',
        refund_status: ''
    },
    isSelectList: [],
    // 更多条件弹窗
    moreCondition: false,
    printSelectList: [{
        label: '已打印',
        value: 1
    }, {
        label: '未打印',
        value: 0
    }],
    remarkSelectList: [{
        label: '有',
        value: 1
    }, {
        label: '无',
        value: 0
    }],
    boolSelectList: [{
        label: '是',
        value: 1
    }, {
        label: '否',
        value: 0
    }],
    remarkSelectList2: [{
        label: '有',
        value: 1
    }, {
        label: '无',
        value: 0
    }, {
        label: '包含',
        value: 2
    }],
    boolSelectList2: [{
        label: '是',
        value: 1
    }, {
        label: '否',
        value: 0
    }, {
        label: '包含',
        value: 2
    }],
    // 获取滚动区域高度
    //订单状态列表
    orderStatusList: [
        // 状态：’WAIT_BUYER_PAY’:’等待买家付款’,’WAIT_SELLER_SEND_GOODS’:’等待卖家发货’,
        // ’SELLER_CONSIGNED_PART’:’卖家部分发货’,’WAIT_BUYER_CONFIRM_GOODS’:’等待买家确认收货’,
        // ’TRADE_BUYER_SIGNED’:’买家已签收（货到付款专用）’,’TRADE_FINISHED’:’交易成功’,’TRADE_CLOSED’:’交易关闭’,
        // ’TRADE_CLOSED_BY_TAOBAO’:’交易被淘宝关闭’,’TRADE_NO_CREATE_PAY’:’没有创建外部交易（支付宝交易）’,
        // ’WAIT_PRE_AUTH_CONFIRM’:’余额宝0元购合约中’,’PAY_PENDING’:’外卡支付付款确认中’,’ALL_WAIT_PAY’:
        // ’所有买家未付款的交易（包含：WAIT_BUYER_PAY、TRADE_NO_CREATE_PAY）’,’ALL_CLOSED’:’所有关闭的交易
        // （包含：TRADE_CLOSED、TRADE_CLOSED_BY_TAOBAO）’,’PAID_FORBID_CONSIGN’:’该状态代表订单已付款但是处于禁止发货状态’,
        {
            type: '',
            label: '全部'
        },
        {
            type: 'WAIT_BUYER_PAY',
            label: '等待买家付款'
        }, {
            type: 'WAIT_SELLER_SEND_GOODS',
            label: '等待卖家发货'
        }, {
            type: 'SELLER_CONSIGNED_PART',
            label: '卖家部分发货'
        }, {
            type: 'WAIT_BUYER_CONFIRM_GOODS',
            label: '等待买家确认收货'
        }, {
            type: 'TRADE_BUYER_SIGNED',
            label: '买家已签收（货到付款专用）'
        }, {
            type: 'TRADE_FINISHED',
            label: '交易成功'
        }, {
            type: 'TRADE_CLOSED',
            label: '交易关闭'
        },
        {
            type: 'TRADE_CLOSED_BY_TAOBAO',
            label: '没有创建外部交易（支付宝交易）'
        },
        {
            type: 'WAIT_PRE_AUTH_CONFIRM',
            label: '余额宝0元购合约中'
        },
        {
            type: 'PAY_PENDING',
            label: '外卡支付付款确认中'
        },
        {
            type: 'ALL_CLOSED',
            label: '所有关闭的交易'
        },
        {
            type: 'PAID_FORBID_CONSIGN',
            label: '等待卖家发货'
        }

    ],
    // 退款状态列表
    orderRefundList: [
        // 状态：’WAIT_SELLER_AGREE’:’买家已经申请退款，等待卖家同意’,’WAIT_BUYER_RETURN_GOODS’:’卖家已经同意退款，
        // 等待买家退货’,’WAIT_SELLER_CONFIRM_GOODS’:’买家已经退货，等待卖家确认收货’,’SELLER_REFUSE_BUYER’:’卖家拒绝退款’,
        // ’CLOSED’:’退款关闭’,’SUCCESS’:’退款成功’,’NO_REFUND’:’没有退货’
        {
            type: '',
            label: '全部'
        },
        {
            type: 'WAIT_SELLER_AGREE',
            label: '买家已经申请退款，等待卖家同意'
        }, {
            type: 'WAIT_BUYER_RETURN_GOODS',
            label: '卖家已经同意退款,等待买家退货'
        }, {
            type: 'WAIT_SELLER_CONFIRM_GOODS',
            label: '买家已经退货，等待卖家确认收货'
        }, {
            type: 'SELLER_REFUSE_BUYER',
            label: '卖家拒绝退款'
        }, {
            type: 'SUCCESS',
            label: '退款成功'
        }, {
            type: 'NO_REFUND',
            label: '没有退货'
        }

    ]



}